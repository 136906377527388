import React from 'react'
import './style.scss'

const HomeSectionRightSvg = ({helloPopuphandle}) =>{
    return(
<svg xmlns="http://www.w3.org/2000/svg" viewBox="1387.891 634.309 613.111 459.056">                
                    <g id="Group_39" data-name="Group 39" transform="translate(0 12)" onClick={()=>helloPopuphandle('togglepop')}>
                      <path id="line-1" className="hometable_blue_1" d="M769,417,373.969,187.563" transform="translate(1231.5 663.5)"></path>
                      <g id="group-15" transform="translate(-45 -2)">
                        <g id="group-11" transform="translate(1435.891 626.852)">
                          <path id="path" className="hometable_blue_2" d="M473.8,275.58,340.58,352.5l233.143,134.6,133.225-76.918Z" transform="translate(-340.58 -230.911)"></path>
                          <line id="line" className="hometable_blue_3" y2="135.693" transform="translate(233.143 256.183)"></line>
                          <line id="line-2" data-name="line" className="hometable_blue_3" y2="138.201" transform="translate(366.367 179.265)"></line>
                          <line id="line-3" data-name="line" className="hometable_blue_3" y2="135.693" transform="translate(0 121.587)"></line>
                          <path id="path-2" data-name="path" className="hometable_blue_4" d="M409.53,334.27" transform="translate(-273.954 -173.127)"></path>
                          <path id="path-3" data-name="path" className="hometable_blue_5" d="M400.27,366.765l101.8,56.6,66.612-38.459V307.99l-99.918-55.21v76.271Z" transform="translate(-283.326 -252.78)"></path>
                          <path id="path-1" className="hometable_blue_6" d="M1126.948,672.671l.176,2.915,4.39,72.8,73.015-41.985-82.37-47.587Z" transform="translate(-1012.23 -450.546)"></path>
                          <line id="line-4" data-name="line" className="hometable_blue_4" x1="65.672" y1="37.479" transform="translate(126.487 217.808)"></line>
                          <path id="path-4" data-name="path" className="hometable_blue_7" d="M391.24,442.815l66.612-37.558V383.53l-65.476,38.459" transform="translate(-265.693 -128.243)"></path>
                          <path id="path-5" data-name="path" className="hometable_blue_2" d="M340.23,450.85l.02-135.83,102.9,58.4-2.978,135.105Z" transform="translate(-314.621 -193.957)"></path>
                          <line id="line-5" data-name="line" className="hometable_blue_3" y2="76.918" transform="translate(125.253 314.826)"></line>
                          <line id="line-6" data-name="line" className="hometable_blue_3" x1="0.274" y2="77.192" transform="translate(25.334 256.874)"></line>
                          <line id="line-7" data-name="line" className="hometable_blue_3" y2="76.095" transform="translate(192.159 277.014)"></line>
                          <line id="line-8" data-name="line" className="hometable_blue_8" x2="99.918" y2="55.856" transform="translate(185.437 76.271)"></line>
                          <g className="botn">
                          <path id="submit-3d" data-name="path" className="hometable_blue_9" d="M417.27,316.182l33.306-18.612,79.249,44.376-32.816,18.789Z" transform="translate(-267.02 -209.818)"></path>
                            <path id="path" className="typing-1" d="M417.27,301.51l7.051-3.94,6.777,3.394-6.947,3.978Z" transform="translate(-255 -195)"></path>
                            <path id="path" className="typing-1" d="M417.27,301.51l7.051-3.94,6.777,3.394-6.947,3.978Z" transform="translate(-230 -195)"></path>
                            <path id="path" className="typing-1" d="M417.27,301.51l7.051-3.94,6.777,3.394-6.947,3.978Z" transform="translate(-220 -175)"></path>
                            <path id="path" className="typing-1" d="M417.27,301.51l7.051-3.94,6.777,3.394-6.947,3.978Z" transform="translate(-195 -180)"></path>
                            <path id="path" className="typing-1" d="M417.27,301.51l7.051-3.94,6.777,3.394-6.947,3.978Z" transform="translate(-195 -160)"></path> 
                              
                          </g>
                             
                        </g>
                        <path id="screen" data-name="path-4" className="hometable_blue_9" d="M1628.637,4579.2v-64.132l91.223,50.359v65.338Z" transform="translate(-3.24 -3880.285)"></path>
                      <g id="code_monitor" transform="translate(1650 675)" >
                      <g id="coding1">
                            <g id="group-2" data-name="group">
                          <g id="group-3" data-name="group" transform="translate(4.745 6.176)">
                            <path id="path" d="M13.38,8.351l.6-.151L6.9,10.083l-.6.151Z" transform="translate(-6.3 -8.2)"></path>
                          </g>
                          <g id="group-4" data-name="group" transform="translate(-1.999 0.22)">
                            <path id="path-2" data-name="path" className="hometable_blue_01" d="M12.2.2l-.377,6.176L4.745,8.26l6.478,8.512-.377,6.176L0,8.636.3,3.364Z" transform="translate(0 -0.2)"></path>
                          </g>
                          <g id="group-5" data-name="group" transform="translate(9.717 3.013)">
                            <path id="path-3" data-name="path" className="hometable_blue_01" d="M26.157,5.28,15.536,33.9,12.9,32.623,23.521,4Z" transform="translate(-12.005 -7.033)"></path>
                          </g>
                        </g>
                        <g id="group-6" data-name="group" transform="translate(22.974 5.875)">
                          <g id="group-7" data-name="group" transform="translate(0.301 14.688)">
                            <path id="path-4" data-name="path" d="M30.9,29.258l.6-.151,7.08-1.808-.527.151Z" transform="translate(-30.9 -27.3)"></path>
                          </g>
                          <g id="group-8" data-name="group" transform="translate(0 19.584)">
                            <path id="path-5" data-name="path" d="M42.4,33.951l.527-.151-11.9,3.164-.527.151Z" transform="translate(-30.5 -33.8)"></path>
                          </g>
                          <g id="group-9" data-name="group" transform="translate(1.069 2.115)">
                            <path id="path-6" data-name="path" className="hometable_blue_01" d="M31.78,8,42.7,22.311l-.3,5.273L30.5,30.748l.3-6.252,7.156-1.808-6.478-8.512Z" transform="translate(-30.5 -8)"></path>
                          </g>
                        </g>

                      </g>
      <g id="coding2" transform="translate(-15 -15)">
      <g id="Group_64" data-name="Group 64" transform="translate(5.034)">
        <path id="Path_9" data-name="Path 9" d="M7.5.559,7.419,2.8c.24-.4.639-.4,1.119-.08A2.657,2.657,0,0,1,9.5,3.835a3.706,3.706,0,0,1,.32,1.678L9.656,8.23l-.8-.639.08-2.557a4.011,4.011,0,0,0-.16-.959,1.867,1.867,0,0,0-.559-.559.641.641,0,0,0-.639-.08.977.977,0,0,0-.24.719l-.08,2.557L6.3,6.153,6.62,0Z" transform="translate(-6.3)"></path>
      </g>
      <g id="Group_65" data-name="Group 65" transform="translate(9.269 3.036)">
        <path id="Path_10" data-name="Path 10" d="M13.038,4.359l-.08,1.119.8.479-.08.959-.8-.479L12.8,8.674a.72.72,0,0,0,.08.4,1.1,1.1,0,0,0,.32.24l.4.24-.08.959-.559-.32a2.849,2.849,0,0,1-.879-.8,5.56,5.56,0,0,1-.16-1.278L12,5.877l-.4-.24.08-.959.4.24.08-1.119Z" transform="translate(-11.6 -3.8)"></path>
      </g>
      <g id="Group_66" data-name="Group 66" transform="translate(11.986 5.593)">
        <path id="Path_11" data-name="Path 11" d="M19.794,9.557a2.822,2.822,0,0,1,1.039,1.119,3.706,3.706,0,0,1,.32,1.678l-.16,2.717-.879-.559.08-2.557a4.011,4.011,0,0,0-.16-.959,2.154,2.154,0,0,0-.559-.639.641.641,0,0,0-.639-.08q-.24.12-.24.719l-.08,2.557-.879-.559.08-2.557a4.011,4.011,0,0,0-.16-.959A2.154,2.154,0,0,0,17,8.838a.641.641,0,0,0-.639-.08q-.24.12-.24.719l-.08,2.557L15,11.554,15.24,7l.959.559v.719c.24-.4.639-.4,1.2-.08a5.17,5.17,0,0,1,.719.639A2.178,2.178,0,0,1,18.6,9.8a.925.925,0,0,1,.559-.4C19.235,9.317,19.475,9.4,19.794,9.557Z" transform="translate(-15 -7)"></path>
      </g>
      <g id="Group_67" data-name="Group 67" transform="translate(18.937 8.07)">
        <path id="Path_12" data-name="Path 12" d="M24.9,10.659l-.24,6.073-.959-.559.32-6.073Z" transform="translate(-23.7 -10.1)"></path>
      </g>
      <g id="Group_68" data-name="Group 68" transform="translate(21.015 10.547)">
        <path id="Path_13" data-name="Path 13" d="M27.578,13.839l1.358,2.717-1.518,1.039L26.3,16.876l1.518-1.039L26.46,13.2Z" transform="translate(-26.3 -13.2)"></path>
      </g>
      <g id="Group_69" data-name="Group 69" transform="translate(0.959 9.908)">
        <path id="Path_14" data-name="Path 14" d="M3.837,13.039,2.319,14.078l1.358,2.717-1.2-.719L1.2,13.439,2.718,12.4Z" transform="translate(-1.2 -12.4)"></path>
      </g>
      <g id="Group_70" data-name="Group 70" transform="translate(4.634 9.908)">
        <path id="Path_15" data-name="Path 15" d="M6.919,12.959,6.839,15.2c.24-.4.639-.4,1.2-.08A2.657,2.657,0,0,1,9,16.235a3.706,3.706,0,0,1,.32,1.678l-.16,2.637-.879-.559.08-2.477a4.011,4.011,0,0,0-.16-.959,2.154,2.154,0,0,0-.559-.639A.641.641,0,0,0,7,15.836a.977.977,0,0,0-.24.719l-.08,2.557L5.8,18.553,6.12,12.4Z" transform="translate(-5.8 -12.4)"></path>
      </g>
      <g id="Group_71" data-name="Group 71" transform="translate(8.789 14.531)">
        <path id="Path_16" data-name="Path 16" d="M14.676,21.873a.6.6,0,0,1-.08.4l-2.637-1.518a1.821,1.821,0,0,0,.24.879,2.154,2.154,0,0,0,.559.639c.32.16.559.16.719-.08l.959.559c-.16.4-.32.639-.639.719a1.518,1.518,0,0,1-1.119-.24A3.486,3.486,0,0,1,11.4,21.873,6,6,0,0,1,11,19.8a1.732,1.732,0,0,1,.559-1.438,1.215,1.215,0,0,1,1.358.08A3.486,3.486,0,0,1,14.2,19.8,4.921,4.921,0,0,1,14.676,21.873Zm-2.717-1.918,1.678.959a1.611,1.611,0,0,0-.24-.879,2.154,2.154,0,0,0-.559-.639.5.5,0,0,0-.559-.08,2.392,2.392,0,0,0-.32.639" transform="translate(-11 -18.186)"></path>
      </g>
      <g id="Group_72" data-name="Group 72" transform="translate(12.864 16.769)">
        <path id="Path_17" data-name="Path 17" d="M19.136,22.116l.879.479-.24,4.554L18.9,26.59v-.8a.8.8,0,0,1-1.2.16,3.354,3.354,0,0,1-1.2-1.358,4.265,4.265,0,0,1-.4-2,1.851,1.851,0,0,1,.559-1.438,1.09,1.09,0,0,1,1.278.08,2.938,2.938,0,0,1,1.119,1.438Zm-.479,3.116a.841.841,0,0,0,.32-.8,2.552,2.552,0,0,0-.24-1.119A1.437,1.437,0,0,0,18.1,22.6a.6.6,0,0,0-.719,0,.841.841,0,0,0-.32.8,2.552,2.552,0,0,0,.24,1.119,1.437,1.437,0,0,0,.639.719,1.08,1.08,0,0,0,.719,0" transform="translate(-16.1 -20.986)"></path>
      </g>
      <g id="Group_73" data-name="Group 73" transform="translate(17.419 18.937)">
        <path id="Path_18" data-name="Path 18" d="M24.836,23.7l.879.559-.24,6.073-.879-.559v-.8a.8.8,0,0,1-1.2.16,3.354,3.354,0,0,1-1.2-1.358,4.265,4.265,0,0,1-.4-2,1.851,1.851,0,0,1,.559-1.438,1.09,1.09,0,0,1,1.278.08,2.938,2.938,0,0,1,1.119,1.438Zm-.559,4.634a.841.841,0,0,0,.32-.8,2.552,2.552,0,0,0-.24-1.119,1.886,1.886,0,0,0-.639-.719.6.6,0,0,0-.719,0,1.065,1.065,0,0,0-.32.8,2.552,2.552,0,0,0,.24,1.119,1.437,1.437,0,0,0,.639.719q.479.24.719,0" transform="translate(-21.8 -23.7)"></path>
      </g>
      <g id="Group_74" data-name="Group 74" transform="translate(22.213 21.414)">
        <path id="Path_19" data-name="Path 19" d="M29.078,27.439l1.358,2.717-1.518,1.039L27.8,30.476l1.518-1.039L27.96,26.8Z" transform="translate(-27.8 -26.8)"></path>
      </g>
      <g id="Group_75" data-name="Group 75" transform="translate(0.479 19.816)">
        <path id="Path_20" data-name="Path 20" d="M3.237,25.439,1.719,26.478l1.358,2.717-1.119-.719L.6,25.839,2.118,24.8Z" transform="translate(-0.6 -24.8)"></path>
      </g>
      <g id="Group_76" data-name="Group 76" transform="translate(3.995 20.375)">
        <path id="Path_21" data-name="Path 21" d="M6.438,25.979,6.358,27.1l.8.479-.08.959-.8-.479L6.2,30.294a.72.72,0,0,0,.08.4,1.1,1.1,0,0,0,.32.24l.479.24L7,32.132l-.559-.32a2.849,2.849,0,0,1-.879-.8,2.668,2.668,0,0,1-.24-1.2L5.4,27.5l-.4-.16.08-.959.4.24.08-1.119Z" transform="translate(-5 -25.5)"></path>
      </g>
      <g id="Group_77" data-name="Group 77" transform="translate(6.632 22.852)">
        <path id="Path_22" data-name="Path 22" d="M8.3,33.234,8.54,28.6l.959.559-.24,4.554Z" transform="translate(-8.3 -28.6)"></path>
      </g>
      <g id="Group_78" data-name="Group 78" transform="translate(6.792 21.194)">
        <path id="Path_23" data-name="Path 23" d="M9.059,28.023a.818.818,0,0,1-.4-.479,1.481,1.481,0,0,1-.16-.559.61.61,0,0,1,.16-.4.357.357,0,0,1,.4,0,.818.818,0,0,1,.4.479,1.481,1.481,0,0,1,.16.559.61.61,0,0,1-.16.4A.357.357,0,0,1,9.059,28.023Z" transform="translate(-8.5 -26.525)"></path>
      </g>
      <g id="Group_79" data-name="Group 79" transform="translate(8.47 22.932)">
        <path id="Path_24" data-name="Path 24" d="M12.038,29.179l-.08,1.119.8.479-.08.959-.8-.479-.08,2.237a.72.72,0,0,0,.08.4,1.1,1.1,0,0,0,.32.24l.4.24-.08,1.039-.559-.32a2.849,2.849,0,0,1-.879-.8,2.668,2.668,0,0,1-.24-1.2l.08-2.237-.32-.32.08-.959.4.24.08-1.119Z" transform="translate(-10.6 -28.7)"></path>
      </g>
      <g id="Group_80" data-name="Group 80" transform="translate(11.107 23.891)">
        <path id="Path_25" data-name="Path 25" d="M15.178,30.459l-.32,6.153-.959-.559.32-6.153Z" transform="translate(-13.9 -29.9)"></path>
      </g>
      <g id="Group_81" data-name="Group 81" transform="translate(12.844 27.076)">
        <path id="Path_26" data-name="Path 26" d="M19.77,37.493a.6.6,0,0,1-.08.4l-2.637-1.518a1.821,1.821,0,0,0,.24.879,2.154,2.154,0,0,0,.559.639c.32.16.559.16.719-.08l.959.559c-.16.4-.32.639-.639.719a1.518,1.518,0,0,1-1.119-.24A3.545,3.545,0,0,1,16.095,35.5a1.732,1.732,0,0,1,.559-1.438,1.215,1.215,0,0,1,1.358.08A3.486,3.486,0,0,1,19.291,35.5,4.856,4.856,0,0,1,19.77,37.493Zm-2.717-1.838,1.678.959a1.611,1.611,0,0,0-.24-.879,2.154,2.154,0,0,0-.559-.639.5.5,0,0,0-.559-.08,1.606,1.606,0,0,0-.32.639" transform="translate(-16.075 -33.886)"></path>
      </g>
      <g id="Group_82" data-name="Group 82" transform="translate(17.259 28.765)">
        <path id="Path_27" data-name="Path 27" d="M22.958,36.639l1.358,2.717L22.8,40.395l-1.2-.719,1.518-1.039L21.76,36Z" transform="translate(-21.6 -36)"></path>
      </g>
      <g id="Group_83" data-name="Group 83" transform="translate(20.855 29.884)">
        <path id="Path_28" data-name="Path 28" d="M29.7,40.117a4.52,4.52,0,0,1,.559,2.477c-.08.879-.32,1.438-.8,1.678a1.984,1.984,0,0,1-1.838-.24L26.1,43.153l.24-5.753,1.518.879A4.522,4.522,0,0,1,29.7,40.117Zm-.8,3.2c.32-.24.4-.639.479-1.2a3.706,3.706,0,0,0-.32-1.678,3.2,3.2,0,0,0-1.119-1.2l-.639-.4-.16,4,.639.4a1.136,1.136,0,0,0,1.119.08" transform="translate(-26.1 -37.4)"></path>
      </g>
      <g id="Group_84" data-name="Group 84" transform="translate(25.489 34.379)">
        <path id="Path_29" data-name="Path 29" d="M35.176,44.763a4.332,4.332,0,0,1,.479,2c0,.719-.24,1.2-.639,1.358-.4.24-.8.16-1.358-.16a3.223,3.223,0,0,1-1.278-1.438,4.332,4.332,0,0,1-.479-2c0-.719.24-1.2.639-1.358.4-.24.8-.16,1.358.16A3.773,3.773,0,0,1,35.176,44.763Zm-.8,2.317a1.133,1.133,0,0,0,.32-.879,2.919,2.919,0,0,0-.24-1.2,1.886,1.886,0,0,0-.639-.719.483.483,0,0,0-.639,0,1.133,1.133,0,0,0-.32.879,2.919,2.919,0,0,0,.24,1.2,1.886,1.886,0,0,0,.639.719.486.486,0,0,0,.639,0" transform="translate(-31.9 -43.026)"></path>
      </g>
      <g id="Group_85" data-name="Group 85" transform="translate(29.704 36.824)">
        <path id="Path_30" data-name="Path 30" d="M39.033,46.337a3.482,3.482,0,0,1,1.119,1.039,3.164,3.164,0,0,1,.559,1.518l-.959-.559a1.153,1.153,0,0,0-.719-.959.483.483,0,0,0-.639,0,1.285,1.285,0,0,0-.24.879,2.539,2.539,0,0,0,.16,1.119,1.41,1.41,0,0,0,.639.719.42.42,0,0,0,.719-.16l.959.559c-.16.4-.32.719-.639.8a1.518,1.518,0,0,1-1.119-.24A3.545,3.545,0,0,1,37.195,47.7a1.732,1.732,0,0,1,.559-1.438A1.09,1.09,0,0,1,39.033,46.337Z" transform="translate(-37.175 -46.086)"></path>
      </g>
      <g id="Group_86" data-name="Group 86" transform="translate(33.719 38.354)">
        <path id="Path_31" data-name="Path 31" d="M45.876,50.157l-.24,4.554-.959-.559v-.719c-.24.4-.639.4-1.2.08a2.657,2.657,0,0,1-.959-1.119,3.706,3.706,0,0,1-.32-1.678L42.36,48l.879.559V51.2a2.027,2.027,0,0,0,.16.959,2.154,2.154,0,0,0,.559.639.641.641,0,0,0,.639.08q.24-.12.24-.719l.08-2.557Z" transform="translate(-42.2 -48)"></path>
      </g>
      <g id="Group_87" data-name="Group 87" transform="translate(38.194 41.07)">
        <path id="Path_32" data-name="Path 32" d="M52.514,53.957a2.822,2.822,0,0,1,1.039,1.119,3.706,3.706,0,0,1,.32,1.678l-.16,2.717-.879-.559.08-2.557a2.027,2.027,0,0,0-.16-.959,2.154,2.154,0,0,0-.559-.639.641.641,0,0,0-.639-.08q-.24.12-.24.719l-.08,2.557-.879-.479.08-2.557a2.027,2.027,0,0,0-.16-.959,2.154,2.154,0,0,0-.559-.639.641.641,0,0,0-.639-.08q-.24.12-.24.719l-.08,2.557-.959-.559.24-4.554.959.559v.719c.24-.4.639-.4,1.2-.08a5.17,5.17,0,0,1,.719.639,2.7,2.7,0,0,1,.479.959.925.925,0,0,1,.559-.4C52.035,53.717,52.275,53.8,52.514,53.957Z" transform="translate(-47.8 -51.4)"></path>
      </g>
      <g id="Group_88" data-name="Group 88" transform="translate(44.885 45.614)">
        <path id="Path_33" data-name="Path 33" d="M59.87,60.693a.6.6,0,0,1-.08.4l-2.637-1.518a1.821,1.821,0,0,0,.24.879,2.154,2.154,0,0,0,.559.639c.32.16.559.16.719-.08l.959.559c-.16.4-.32.639-.639.719a1.518,1.518,0,0,1-1.119-.24A3.545,3.545,0,0,1,56.195,58.7a1.732,1.732,0,0,1,.559-1.438,1.215,1.215,0,0,1,1.358.08A3.486,3.486,0,0,1,59.391,58.7,3.531,3.531,0,0,1,59.87,60.693Zm-2.637-1.838,1.678.959a1.611,1.611,0,0,0-.24-.879,2.154,2.154,0,0,0-.559-.639.5.5,0,0,0-.559-.08.83.83,0,0,0-.32.639" transform="translate(-56.175 -57.086)"></path>
      </g>
      <g id="Group_89" data-name="Group 89" transform="translate(49.141 47.463)">
        <path id="Path_34" data-name="Path 34" d="M63.817,60.519a2.657,2.657,0,0,1,.959,1.119,3.706,3.706,0,0,1,.32,1.678l-.16,2.717-.879-.559.08-2.557a4.011,4.011,0,0,0-.16-.959,2.154,2.154,0,0,0-.559-.639.641.641,0,0,0-.639-.08q-.24.12-.24.719l-.08,2.557-.959-.559.24-4.554.959.479V60.6C62.938,60.2,63.338,60.2,63.817,60.519Z" transform="translate(-61.5 -59.4)"></path>
      </g>
      <g id="Group_90" data-name="Group 90" transform="translate(53.455 48.981)">
        <path id="Path_35" data-name="Path 35" d="M68.338,61.779l-.08,1.119.8.479-.08.959-.8-.479-.08,2.237a.72.72,0,0,0,.08.4,1.1,1.1,0,0,0,.32.24l.479.24-.08.959-.559-.32a2.849,2.849,0,0,1-.879-.8,2.668,2.668,0,0,1-.24-1.2l.08-2.237-.4-.24.08-.959.4.24.08-1.119Z" transform="translate(-66.9 -61.3)"></path>
      </g>
      <g id="Group_91" data-name="Group 91" transform="translate(56.332 52.097)">
        <path id="Path_36" data-name="Path 36" d="M73.217,65.839,71.7,66.878l1.278,2.717-1.119-.719L70.5,66.239,72.018,65.2Z" transform="translate(-70.5 -65.2)"></path>
      </g>
      <g id="Group_92" data-name="Group 92" transform="translate(59.688 51.298)">
        <path id="Path_37" data-name="Path 37" d="M77.816,64.679l-2.237,8.31L74.7,72.51l2.157-8.31Z" transform="translate(-74.7 -64.2)"></path>
      </g>
      <g id="Group_93" data-name="Group 93" transform="translate(63.044 54.494)">
        <path id="Path_38" data-name="Path 38" d="M80.338,68.679l-.08,1.119.8.479v.959l-.8-.479-.08,2.237a.72.72,0,0,0,.08.4,1.1,1.1,0,0,0,.32.24l.4.24-.08.959-.559-.32a2.849,2.849,0,0,1-.879-.8,2.668,2.668,0,0,1-.24-1.2l.08-2.237-.4-.24.08-.959.4.24.08-1.119Z" transform="translate(-78.9 -68.2)"></path>
      </g>
      <g id="Group_94" data-name="Group 94" transform="translate(65.84 55.313)">
        <path id="Path_39" data-name="Path 39" d="M82.959,70.723a.818.818,0,0,1-.4-.479,1.481,1.481,0,0,1-.16-.559.61.61,0,0,1,.16-.4.357.357,0,0,1,.4,0,.818.818,0,0,1,.4.479,1.481,1.481,0,0,1,.16.559.61.61,0,0,1-.16.4A.357.357,0,0,1,82.959,70.723Z" transform="translate(-82.4 -69.225)"></path>
      </g>
      <g id="Group_95" data-name="Group 95" transform="translate(65.761 56.971)">
        <path id="Path_40" data-name="Path 40" d="M82.3,75.854l.16-4.554.959.559-.24,4.554Z" transform="translate(-82.3 -71.3)"></path>
      </g>
      <g id="Group_96" data-name="Group 96" transform="translate(67.598 56.971)">
        <path id="Path_41" data-name="Path 41" d="M85.958,71.859l-.08,1.119.8.479-.08.959-.8-.479-.08,2.237a.72.72,0,0,0,.08.4,1.1,1.1,0,0,0,.32.24l.4.24v.959l-.559-.32a1.915,1.915,0,0,1-.8-.8,2.8,2.8,0,0,1-.24-1.278L85,73.377l-.4-.24.08-.959.4.24.08-1.119Z" transform="translate(-84.6 -71.3)"></path>
      </g>
      <g id="Group_97" data-name="Group 97" transform="translate(70.235 58.01)">
        <path id="Path_42" data-name="Path 42" d="M89.1,73.159l-.32,6.073-.879-.479.24-6.153Z" transform="translate(-87.9 -72.6)"></path>
      </g>
      <g id="Group_98" data-name="Group 98" transform="translate(71.893 61.195)">
        <path id="Path_43" data-name="Path 43" d="M93.67,80.193a.6.6,0,0,1-.08.4l-2.637-1.518a1.821,1.821,0,0,0,.24.879,2.154,2.154,0,0,0,.559.639c.32.16.559.16.719-.08l.959.559c-.16.4-.32.639-.639.719a1.518,1.518,0,0,1-1.119-.24A3.545,3.545,0,0,1,89.995,78.2a1.732,1.732,0,0,1,.559-1.438,1.215,1.215,0,0,1,1.358.08A3.487,3.487,0,0,1,93.191,78.2,4.581,4.581,0,0,1,93.67,80.193Zm-2.637-1.838,1.678.959a1.611,1.611,0,0,0-.24-.879,2.154,2.154,0,0,0-.559-.639.5.5,0,0,0-.559-.08.83.83,0,0,0-.32.639" transform="translate(-89.975 -76.586)"></path>
      </g>
      <g id="Group_99" data-name="Group 99" transform="translate(76.308 62.884)">
        <path id="Path_44" data-name="Path 44" d="M96.858,79.339l1.358,2.637L96.7,83.015l-1.2-.639,1.518-1.039L95.74,78.7Z" transform="translate(-95.5 -78.7)"></path>
      </g>
      <g id="Group_100" data-name="Group 100" transform="translate(0 29.724)">
        <path id="Path_45" data-name="Path 45" d="M2.637,37.839,1.119,38.878l1.358,2.717-1.119-.719L0,38.239,1.518,37.2Z" transform="translate(0 -37.2)"></path>
      </g>
      <g id="Group_101" data-name="Group 101" transform="translate(3.356 28.925)">
        <path id="Path_46" data-name="Path 46" d="M7.236,36.759l-2.157,8.23L4.2,44.51,6.357,36.2Z" transform="translate(-4.2 -36.2)"></path>
      </g>
      <g id="Group_102" data-name="Group 102" transform="translate(6.872 31.562)">
        <path id="Path_47" data-name="Path 47" d="M9.719,40.059,9.639,42.3c.24-.4.639-.4,1.2-.08a2.657,2.657,0,0,1,.959,1.119,3.706,3.706,0,0,1,.32,1.678l-.16,2.717-.879-.639.08-2.557a4.011,4.011,0,0,0-.16-.959,2.154,2.154,0,0,0-.559-.639.641.641,0,0,0-.639-.08c-.08.24-.16.479-.24.8l-.08,2.557L8.6,45.653,8.92,39.5Z" transform="translate(-8.6 -39.5)"></path>
      </g>
      <g id="Group_103" data-name="Group 103" transform="translate(11.006 36.265)">
        <path id="Path_48" data-name="Path 48" d="M17.47,48.993a.6.6,0,0,1-.08.4l-2.637-1.518a1.821,1.821,0,0,0,.24.879,2.154,2.154,0,0,0,.559.639c.32.16.559.16.719-.08l.959.559c-.16.4-.32.639-.639.719a1.518,1.518,0,0,1-1.119-.24A3.545,3.545,0,0,1,13.795,47a1.732,1.732,0,0,1,.559-1.438,1.215,1.215,0,0,1,1.358.08A3.486,3.486,0,0,1,16.991,47,4.332,4.332,0,0,1,17.47,48.993Zm-2.637-1.838,1.678.959a1.611,1.611,0,0,0-.24-.879,2.154,2.154,0,0,0-.559-.639.5.5,0,0,0-.559-.08.83.83,0,0,0-.32.639" transform="translate(-13.775 -45.386)"></path>
      </g>
      <g id="Group_104" data-name="Group 104" transform="translate(15.102 38.502)">
        <path id="Path_49" data-name="Path 49" d="M21.936,49.236l.879.559-.24,4.554L21.7,53.79v-.8a.8.8,0,0,1-1.2.16,3.354,3.354,0,0,1-1.2-1.358,4.265,4.265,0,0,1-.4-2,1.851,1.851,0,0,1,.559-1.438,1.09,1.09,0,0,1,1.278.08,2.938,2.938,0,0,1,1.119,1.438Zm-.479,3.116a.841.841,0,0,0,.32-.8,2.552,2.552,0,0,0-.24-1.119,1.437,1.437,0,0,0-.639-.719.6.6,0,0,0-.719,0,.841.841,0,0,0-.32.8,2.552,2.552,0,0,0,.24,1.119,1.437,1.437,0,0,0,.639.719,1.08,1.08,0,0,0,.719,0" transform="translate(-18.9 -48.186)"></path>
      </g>
      <g id="Group_105" data-name="Group 105" transform="translate(19.576 40.591)">
        <path id="Path_50" data-name="Path 50" d="M27.616,50.8l.879.559-.32,6.153-.879-.559v-.8a.8.8,0,0,1-1.2.16,3.354,3.354,0,0,1-1.2-1.358,4.265,4.265,0,0,1-.4-2,1.851,1.851,0,0,1,.559-1.438,1.09,1.09,0,0,1,1.278.08,2.938,2.938,0,0,1,1.119,1.438Zm-.479,4.634a.841.841,0,0,0,.32-.8,2.552,2.552,0,0,0-.24-1.119,1.886,1.886,0,0,0-.639-.719.6.6,0,0,0-.719,0,1.065,1.065,0,0,0-.32.8,2.552,2.552,0,0,0,.24,1.119,1.437,1.437,0,0,0,.639.719.6.6,0,0,0,.719,0" transform="translate(-24.5 -50.8)"></path>
      </g>
      <g id="Group_106" data-name="Group 106" transform="translate(24.45 43.068)">
        <path id="Path_51" data-name="Path 51" d="M31.958,54.539l1.278,2.717-1.518,1.039L30.6,57.576l1.518-.959L30.76,53.9Z" transform="translate(-30.6 -53.9)"></path>
      </g>
    </g>
                        </g>
                      </g>

                        </g>
                      
                    
                  </svg>
    )
}
export default HomeSectionRightSvg