import React from 'react'
import LoaderImage from '../assets/images/loader.gif'
const FixedLoader = ({showloader})=>{
    return(<div className="loader" style={{display: `${showloader ? `block`:`none`}`}}>
    <img className="loader-img" src={LoaderImage} alt="loader_image"/>
    </div>)
}

const AbsoluteLoader = ({showloader})=>{
    return(<div className="absolute_loader" style={{display: `${showloader ? `block`:`none`}`}}>
    <img className="loader-img" src={LoaderImage} alt="loader_image"/>
    </div>)
}

export {FixedLoader,AbsoluteLoader}