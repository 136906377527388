import React from 'react'
import './style.scss'

const HomeSectionLeftSvg =()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" viewBox="-0.954 517.55 369.026 574.823">
                    <defs>                    
                      <clipPath id="homeboard_blue_clip-path">
                        <path id="path" d="M.134,64.64,0,19.85,34.37,0,34.5,44.79Z"></path>
                      </clipPath>
                    </defs>
                    <g id="group-226" transform="translate(0 12)">
                      <g id="group-12" transform="translate(97.288 504.68)">
                        <path id="path-2" data-name="path" className="homeboard_blue_1" d="M.5,93.247V351.929l160.017-92.377V.87Z" transform="translate(0 0)"></path>
                        <path id="path-3" data-name="path" className="homeboard_blue_2" d="M101.5,250.894l-32-18.487V10.68" transform="translate(60.896 8.658)"></path>
                        <line id="line" className="homeboard_blue_2" x1="128.014" y2="73.909" transform="translate(0.5 241.065)"></line>
                      </g>
                      <path id="line-1" className="homeboard_blue_3" d="M247.047,368.008,615.07,154.867" transform="translate(-247.5 711.5)"></path>
                      <g transform="matrix(0.995, -0.105, 0.105, 0.995, 108.629, 624.569)">
                        <path id="language_c" className="homeboard_blue_4" d="M23.744,1.011a20.376,20.376,0,0,1,10.435.973c2.2.973,3.57,1.945,6.316,4.7L33.63,17.059c-3.158-2.918-6.179-4.052-9.474-3.4C17.016,14.79,12.21,22.246,12.21,31.81c0,9.888,2.548,12.589,9.962,11.454,3.707-.648,6.454-2.594,8.788-6.322l6.316,8.429c-5.355,6.646-10.024,9.564-16.477,10.7-6.728,1.135-10.2.393-14.181-4.632C2.911,46.737,1.5,41.7,1.5,34.08,1.5,25,4.383,16.087,9.327,9.765A22.072,22.072,0,0,1,23.744,1.011Z"></path>
                      </g>
                      <g id="language_hash" transform="translate(154.074 596.604) rotate(-7)">
                        <path id="path-5" data-name="path" className="homeboard_blue_5" d="M19.834,20.211H15.522l-1.38,9.26H10.52l1.293-9.26H7.847l-1.38,9.26H2.932l1.38-9.26H.086L0,16.56H4.829l.6-4.185H1.293l-.086-3.65H5.95L7.244,0h3.449L9.486,8.725h4.139L14.746,0h3.622L17.074,8.725h3.794l.086,3.65h-4.4l-.517,4.185h3.794ZM8.882,12.376,8.365,16.56h4.053l.69-4.185H8.882Z"></path>
                      </g>
                      <g id="language_angular" transform="translate(177.028 658.688)">
                        <g id="group-2" data-name="group">
                          <path id="path-6" data-name="path" className="homeboard_blue_4" d="M51.047,76,48,86.167,51.047,84.4l.171-.085,3.36-1.937L51.047,76" transform="translate(-34.359 -60.992)"></path>
                          <path id="path-7" data-name="path" className="homeboard_blue_4" d="M6.508,62.23,16.76,29.879h0L27.639,50.127l-3.674,2.05L21.687,47.45l-4.841,2.791h0l-4.414,2.535-2.05,7.148L6.508,62.23M16.788,26.376.1,42.779,2.805,66.417l14.1.769,14.125-17.2L33.591,23.3l-16.8,3.076" transform="translate(-0.1 -23.3)"></path>
                        </g>
                      </g>
                      <g transform="translate(122 718.26)">
                        <g id="language_html5" className="homeboard_blue_6">
                          <path id="path-8" data-name="path" className="homeboard_blue_5" d="M34.37,0,0,19.85,3.246,58.361,17.3,54.728l14.066-12.61L34.37,0M7.627,38.672l-1.1-13.011-.107-1.269,10.78-6.225.013-.013,10.767-6.212-.094,1.389L27.7,15.95l-.08,1.162-.013.013L17.219,23.109l-.013.013-6.078,3.5.414,4.836,5.677-3.273.013-.013,8.83-5.1,1.162-.668-.107,1.389L26.155,37.4l-.067.868-8.8,7.895h0l-.027.013L8.442,48.45l-.628-7.414L9.8,39.887l2.324-1.349.321,3.767,4.8-1.282h0l4.8-4.261.481-6.706L17.232,33.1v.027l-9.6,5.544"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
    )
}
export default HomeSectionLeftSvg;
