import React from 'react'
import './style.scss'

const LampSvg =()=>{
    return(
<svg xmlns="http://www.w3.org/2000/svg" viewBox="479 2165 60 223">
                     <g id="lamp_blue" transform="translate(-460 2172)">
                      <path id="path-10" className="lamp_blue_1" d="M0-3825.831a26.959,26.959,0,0,1,1.489-8.864l-.059-.029.326-.7a26.87,26.87,0,0,1,1.6-3.443l12.939-27.906c1.717-4.313,4.974-7.224,8.71-7.224h3c4.063,0,7.559,3.443,9.125,8.388l14.7,28.978-.067.008a26.908,26.908,0,0,1,2.245,10.8,27,27,0,0,1-27,27A27,27,0,0,1,0-3825.831Z" transform="translate(939 4014.831)"></path>
                      <g id="rectangle-6" className="lamp_blue_2" transform="translate(957 128)">
                        <rect className="lamp_blue_4" width="18" height="21" rx="9"></rect>
                        <rect className="lamp_blue_5" x="1.5" y="1.5" width="15" height="18" rx="7.5"></rect>
                      </g>
                      <g id="rectangle-7" className="lamp_blue_3" transform="translate(963 -7)">
                        <rect className="lamp_blue_4" width="6" height="146" rx="3"></rect>
                        <rect className="lamp_blue_5" x="1" y="1" width="4" height="144" rx="2"></rect>
                      </g>
                    </g>
                    <g id="lamp_white" transform="translate(-458 2170)">
                      <path id="path-10" className="lamp_blue_6" d="M0-3825.831a26.959,26.959,0,0,1,1.489-8.864l-.059-.029.326-.7a26.87,26.87,0,0,1,1.6-3.443l12.939-27.906c1.717-4.313,4.974-7.224,8.71-7.224h3c4.063,0,7.559,3.443,9.125,8.388l14.7,28.978-.067.008a26.908,26.908,0,0,1,2.245,10.8,27,27,0,0,1-27,27A27,27,0,0,1,0-3825.831Z" transform="translate(939 4014.831)"></path>
                      <rect id="rectangle-6" className="lamp_blue_7" width="18" height="21" rx="9" transform="translate(957 128)"></rect>
                      <rect id="rectangle-7" className="lamp_blue_7" width="6" height="146" rx="3" transform="translate(963 -7)"></rect>
                    </g>
                  </svg>
    )
}
export default LampSvg;