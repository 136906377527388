import React,{useState,useEffect} from 'react'
import {Link} from 'gatsby'
import Slider from "react-slick";
import HomePageSection1 from './home-page-section'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../components/assets/styles/all.scss' 


export const IndexPageTemplate = () => {
  const[homepageshow,setHomepageshow] = useState(true)
  const[active,setActive]=useState(0)
  const[country,setCountry]=useState('Belgium')
  const sliderItems = [
    {
      countryname:'Belgium',
      image1:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/Belgum.svg',
      image2:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/belgium-c.svg'
    },
    {
      countryname:'Australia',
      image1:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/Australian.svg',
      image2:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/australia-c.svg'
    },
    {
      countryname:'Belgium',
      image1:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/Belgum.svg',
      image2:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/belgium-c.svg'
    },
    {
      countryname:'Australia',
      image1:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/Australian.svg',
      image2:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/australia-c.svg'
    },
    {
      countryname:'Belgium',
      image1:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/Belgum.svg',
      image2:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/belgium-c.svg'
    },
    {
      countryname:'Australia',
      image1:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/Australian.svg',
      image2:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/australia-c.svg'
    },
    {
      countryname:'Belgium',
      image1:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/Belgum.svg',
      image2:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/belgium-c.svg'
    },
    {
      countryname:'Australia',
      image1:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/Australian.svg',
      image2:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/australia-c.svg'
    },
    {
      countryname:'Belgium',
      image1:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/Belgum.svg',
      image2:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/belgium-c.svg'
    },
    {
      countryname:'Australia',
      image1:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/Australian.svg',
      image2:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/australia-c.svg'
    },
    {
      countryname:'Belgium',
      image1:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/Belgum.svg',
      image2:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/belgium-c.svg'
    },
    {
      countryname:'Australia',
      image1:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/Australian.svg',
      image2:'https://www.jouret-development.com/hubfs/landing%20page%20new%202018/svg_images/australia-c.svg'
    }
  ]


  useEffect(()=>{
  },[active])

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 8,
  }

  const countryClicked=(i,country)=>{
    setActive(i)
    setCountry(country)
  }
 return(
  <React.Fragment>
    
      {homepageshow 
      ?
      <HomePageSection1/>
      : <div className="popup-open">
      <div className="page-popup">
      <div className="wrappers">
        <div className="top-wrapper">
            <div className="top-line">
                <h1>Hej! Welcome back to a our web development agency</h1>
            </div>
            <div className="rev-block-container">
                <h5 className="rev-block">
                    <span>pick up your nationality:</span>
                </h5>
            </div>
        </div>
          <Slider className="popslider" {...settings}>
        {sliderItems.map((dt,i)=>{
          return ( <div className={`popitem ${i === active ?'active':''}`} key={i} id={`country${i}`} onClick={()=>countryClicked(i,dt.countryname)}>
          <img src={dt.image1} alt={`image_${dt.countryname}`} className="img-no-action" />
          <img src={dt.image2} alt={`active_image_${dt.countryname}`} className="img-click"/>
        <span className="country-name nation-head">{dt.countryname}</span>
          </div>)
        })}
           
            </Slider>
           
        <div className="btn-container nation-cta">
      <Link className="cta" to="/">I am from <span className="country selected-language">{country}</span>, let's continue</Link>
        </div>
    </div>
     
   
        </div>
         
     
   
        </div>}
  

  </React.Fragment>
  
)}


const IndexPage = () => {

  return (
   
      <IndexPageTemplate/>
   
  )
}



export default IndexPage
