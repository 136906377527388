import React,{useState,useEffect} from 'react'
import LampSvg from '../assets/images/lamp'
import HomeSectionLeftSvg from '../assets/images/home-section-left'
import HomeSectionRightSvg from '../assets/images/home-section-right'
import Redline from '../assets/images/red_line'
import Greenbg from '../assets/images/green_bg'
import Getstarted from '../assets/images/getstarted'
import SlideHomeBoardWhilteSvg from '../assets/images/slide-home-board-white'
import SlideHomeBoardTable from '../assets/images/slide-home-board-table'
import DevloperBg from '../assets/images/white_bg'
import HelloPopup from './hello_popup'
import queryString from 'query-string'
import {Link} from 'gatsby'
import {navigate} from 'gatsby'
const HomeSection1 = ({bulbClick}) =>{
const windowGlobal = typeof window !== 'undefined' && window
const [expdata,setExpdata] = useState('')
const [expdatafontsize, setExpdatafontsize] = useState(`inherit`)
const [helloPopup,setHelloPopup] = useState(false)
const [dev,setDev] = useState(false)

const [heightw,setHeightw] = useState(windowGlobal.innerHeight)


  const helloPopuphandle=(e)=>{
      if(e === 'close'){
        setHelloPopup(false)
      }
      if(e === 'togglepop'){
        setHelloPopup(!helloPopup)
      }
  }

  useEffect(()=>{
    if(window !== undefined){
      if(heightw === undefined){
        setTimeout(() => {
          setHeightw(window.innerHeight)
        }, 100);
        
      }
     
      const x = queryString.parse(window.location.search).exp
      if(x){
        localStorage.setItem('expert_name',x)
        navigate('/')
      }
      if(localStorage.getItem('expert_name')){
      const t=localStorage.getItem('expert_name')
      if(t.length <= 7){
        setExpdata(t)
        if(windowGlobal.innerWidth < 450 && t.length > 4){
          setDev(true)
        }
      }else{
        setExpdata(t)
        setExpdatafontsize(`${100 - t.length}%`)
        if(windowGlobal.innerWidth < 450 && t.length > 4){
          setDev(true)
        }

      }}
      
    
      }
  },[helloPopup, dev])
  const styles={left:`50%`, transform:`translateX(-50%)`}
    return (
        <div 
        className={`section section0 fp-section active fp-table fp-completely`} 
        id="section0" 
        style={{height:heightw === undefined ? `100vh` : `${heightw}px`}}>
          <div className="fp-tableCell" style={{height:heightw === undefined ? `100vh` : `${heightw}px`}}>
          <div className="blub-img" onClick={bulbClick}>
           <LampSvg/>
        </div>
        <div className="mid-text-section">

      <h1 className="en-text">
          <strong className="unl"><b className="home-slide1">Stop</b>
            <span>
            <Redline/>
            </span>
          </strong>
            <span className="home-slide2">wasting <br/>a lot of</span>
          <strong className="green-box"><b className="home-slide3">time</b>
            <span>
            <Greenbg/>
            </span>
          </strong> <span className="home-slide4">searching for </span>

          <strong className={`white-box ${expdata.length > 0 ? ``:`ty32`}`} style={expdata.length >5 ? styles : null}>
            <span>
            <DevloperBg/>
            </span>
            <b className="home-slide5" style={{fontSize:`${expdatafontsize}`}}>
              {`${expdata} `} {!dev && <hm>developers</hm>}</b>
              {dev && <div style={{color:'#fff'}}>developers</div>}
              
          </strong>
        </h1>

                            
      <p className="str-pgh home-slide6 en-text">And start with a new chapter today.</p>
                           
      <div>
    </div>
  </div>
        <Link to="/story" className="get-start-btn en-text">
          <span>The story</span>
         {/* <Getstarted text={`The story`}/> */}
        </Link> 
        <div className="slide-img1">
          <HomeSectionLeftSvg/>
        </div>
        <div className="slide_home_board_white">
          <SlideHomeBoardWhilteSvg/>
        </div>
        
        <HelloPopup helloPopup={helloPopup} helloPopuphandle={helloPopuphandle}/>
        <div className="slide-img2">
         <HomeSectionRightSvg helloPopuphandle={helloPopuphandle}/>
                        </div>
        <div className="slide_home_table_white">
          <SlideHomeBoardTable helloPopuphandle={helloPopuphandle}/>
        </div>
          </div>
        
            
              
              </div>
       
    );
}

export default HomeSection1;