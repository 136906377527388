import React,{useState,useEffect,useRef} from 'react'
import {navigate} from 'gatsby'
import {
  isMobile
} from "react-device-detect";
import './style.scss'
const windowGlobal = typeof window !== 'undefined' && window
const Hello_pop_svg=({helloPopup})=>{
  const wr = useRef(null)
  const [formdata,setFormdata]=useState({})
  const [subscribed,setSubscribed]= useState(false)
  const encode = (data) => {
      return Object.keys(data)
          .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
          .join("&");
    }
    const handleSubmit=(e)=>{
      const form = e.target
      fetch("/", {
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": form.getAttribute('name'), ...formdata })
        })
          .then(() =>navigate('/thank-you-page'))
          .catch(error => alert(error));
        e.preventDefault();
    }
    const formonChange =(e)=>{
      setFormdata({...formdata,[e.target.name]:e.target.value})
    }
    useEffect(()=>{
   
        if(helloPopup && windowGlobal.innerWidth > 1023 && !isMobile){
         setTimeout(() => {
          wr.current.focus()
         }, 1000);
        }
    },[subscribed,helloPopup])
    return(
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1212 1369 700.93 711">
         <path id="Union_1" data-name="Union 1" className="code-1" d="M1873-484v-709h599v346.033l100.12,138.474L2472-771.549V-484Z" transform="translate(-3084 2563)"></path>
          <g xmlSpace="preserve" className="html-code active">

          <foreignObject x="120" y="120" height="100%" width="100%" style={{transform: `translate(-120px,-120px)`}}>
    
          <form 
          id="helloform" 
          name="hello_form"
          method="post"
          netlify-honeypot="bot-field"
          data-netlify="true"
          autocomplete="off"
          onSubmit={handleSubmit}>
      
          <input type="hidden" name="form-name" value="hello_form" />
          <p hidden>
          <label>
              Don’t fill this out: <input name="bot-field" onChange={formonChange} />
          </label>
          </p>
          <pre style={{paddingLeft:`60px`}}>   
            &lt;!DOCTYPE html &gt;    <br/>
            {' '}&lt;html&gt;   <br/>
            {' '}&lt;body&gt;   <br/>
            <br/>
            {' '}&lt;p&gt;  
            <input id="helloname" ref={wr} name="helloname" placeholder="Your Name" required type="text" onChange={formonChange}/>   
            &lt;/p&gt;
            <br/> <br/>
            {' '}&lt;script&gt;   <br/>
            {' '}{' '}{' '}alert('<input id="helloworld" placeholder="Hello, World!" required type="text" onChange={formonChange}/>');   <br/>
            {' '}&lt;/script&gt;
             <br/>
             <br/>
             {' '}&lt;p&gt;  
             <input id="helloemail" name="helloemail" placeholder="Your E-mail" required type="email" onChange={formonChange}/>  
            &lt;/p&gt;
            <br/>
            <br/>
            {' '}&lt;/body&gt;   <br/>
            {' '}&lt;/html&gt;
             </pre>
                <input className="hidden" id="hello-submit" type="submit"/>
             </form>
              </foreignObject>
              </g>

             {/* <g xmlSpace="preserve" className="html-output">
                 <foreignObject x="120" y="120" width="400" height="100%" 
                 style={{transform: `translate(-220px,-300px)`,
                  textAlign: `center`}}>

    
                <p id="hname"></p>
                <p id="hello"></p>
                <p id="email"></p>
                
                </foreignObject>
                </g>  */}

                  
                </svg>    
    )
}
export default Hello_pop_svg;