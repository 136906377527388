import React,{useState,useEffect} from 'react'
import {Link} from 'gatsby'
import Layout from '../layouts'
import HomeSection1 from '../components/home/section1'
import NavMenu from '../components/home/navmenu'
import { FixedLoader } from '../components/loader'
import Helmet from 'react-helmet'
import MenuHomeSvg from '../components/assets/images/menu-home'
import MessageSvgImage from '../components/assets/images/message'

const HomePageSection1 = () =>{
    const[mobileview,setMobileview]=useState(true)
    const [pageloaded, setPageloaded]=useState(false)
    const [bulb, setBulb] = useState(false);
    const bulbClick = () =>{
        setBulb(!bulb)
    }
    useEffect(()=>{
      if(window !== undefined){
       if(window.screen.width < 1024){
        setMobileview(false)
       }
      }
      setPageloaded(true)
    },[pageloaded,mobileview])
  
    return(
      <React.Fragment>
        {/* <FixedLoader showloader={pageloaded}/>  */}
        <Layout bulb={bulb} pageloaded={pageloaded} page={pageloaded ? 'fp-viewing-home' : ``}>     
          <Fullpage bulbClick={bulbClick} setPageloaded={setPageloaded} mobileview={mobileview}/>
        </Layout>
        </React.Fragment>
    )
}
export default HomePageSection1;



const Fullpage = ({bulbClick,setPageloaded,mobileview}) => {
  const [title, setTitle] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  useEffect(()=>{
    if(window !== undefined){
      if(`${window.location.origin}/` === window.location.href){
        setTitle(false)
      }else{
        setTitle(true)
      }
    }
  },[activeSection])
  return(
    <React.Fragment>
       {title ? <Helmet defer={false}>
        <title>Senior Web Development Agency | JDSolutions </title>
      </Helmet> : null}
    <NavMenu>
    {/* {`${activeSection.charAt(0).toUpperCase()}${activeSection.slice(1)}`} */}
    <li>
    <Link activeClassName="active" className={`home`} to="/"><MenuHomeSvg/></Link>
    <div className="fp-tooltip right">home</div>
    </li>
    <li><Link activeClassName="active" to="/story#introduction"><span/></Link>
    <div className="fp-tooltip right">introduction</div>
    </li>
    <li><Link activeClassName="active" to="/story#problem" ><span/></Link>
    <div className="fp-tooltip right">problem</div>
    </li>
    <li><Link  activeClassName="active" to="/story#answer"><span/></Link>
    <div className="fp-tooltip right">answer</div>
    </li>
    <li><Link  activeClassName="active" to="/story#relief"><span/></Link>
    <div className="fp-tooltip right">relief</div>
    </li>
    <li><Link  activeClassName="active" to="/story#story"><span/></Link>
    <div className="fp-tooltip right">story</div>
    </li>
    <li><Link  activeClassName="active" to="/story#nowadays" ><span/></Link>
    <div className="fp-tooltip right">nowadays</div>
    </li>
    <li><Link  activeClassName="active" to="/story#unravel"><span/></Link>
    <div className="fp-tooltip right">unravel</div>
    </li>
    <li><Link  activeClassName="active" to="/story#lastwords" ><span/></Link>
    <div className="fp-tooltip right">lastwords</div>
    </li>
    <li><Link  activeClassName="active" to="/story#contact" className="contacts"><MessageSvgImage/></Link>
    <div className="fp-tooltip right">contact</div>
    </li>
    
    
    </NavMenu>
    <HomeSection1 bulbClick={bulbClick}/>
    </React.Fragment>
  )
}