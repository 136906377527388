import React from 'react'
import Hello_pop_svg from '../assets/images/home_hello_popup'

const HelloPopup = ({helloPopup,helloPopuphandle}) =>{
        return(
            <div className={`code ${helloPopup ? `scale-code` : ''}`}>
                <div className="code-head">
                  <h3>Hello!</h3>
                  <a to="#" style={{cursor:'pointer'}} className="close" onClick={()=>helloPopuphandle(`close`)}></a>
                </div> 
                <Hello_pop_svg helloPopup={helloPopup}/>              
  
                <label className="" htmlFor="hello-submit">Preview</label>               


                </div>
        )
}
export default HelloPopup