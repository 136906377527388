import React from 'react'
import './style.scss'

const SlideHomeBoardTable = ({helloPopuphandle}) =>{
    return(
      <React.Fragment>
      {helloPopuphandle ? 
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="1272.967 1441.897 728.286 733.9">
                    <defs>
                      <clipPath id="table_white_home_clip-path">
                        <path id="path" d="M0,125.1.332,0,176.416,101.638l-.332,125.1Z"></path>
                      </clipPath>
                      <clipPath id="table_white_home_clip-path-2">
                        <path id="path-2" data-name="path" d="M0,15.283,26.305,0,80.091,31.155,53.786,46.438Z"></path>
                      </clipPath>
                      <clipPath id="table_white_home_clip-path-3">
                        <path id="path-3" data-name="path" d="M0,15.616,26.881,0l48.7,28.132L48.7,43.748Z"></path>
                      </clipPath>
                    </defs>
                    <g id="group-229" transform="translate(0 1092)" style={{cursor:'pointer'}} onClick={helloPopuphandle ? ()=>helloPopuphandle('togglepop') : null}>
                      <path id="line-1" className="table_white_home_1" d="M769,417,373.969,187.563" transform="translate(1231.5 665.5)"></path>
                      <g id="group-13" transform="translate(87 253.601)">
                        <ellipse id="ellipse" className="table_white_home_2" cx="54.376" cy="73.32" rx="54.376" ry="73.32" transform="translate(1426.806 105.587) rotate(-4.9)"></ellipse>
                        <ellipse id="ellipse-2" data-name="ellipse" className="table_white_home_3" cx="42.238" cy="65.262" rx="42.238" ry="65.262" transform="translate(1431.824 113.869) rotate(-4.9)"></ellipse>
                        
                        <g id="clock_dial">
                          <path id="path-3-2" data-name="path-3" className="table_white_home_4" d="M0,52.943,22.92,66.179ZM0,0V0Z" transform="translate(1476.928 127.145)"></path>
                        </g>
                      </g>
                      <g id="group-57" transform="translate(1293 764.716)">
                        <path id="path-4" data-name="path" className="table_white_home_5" d="M1,46.485,79.922,91.842,130.419,61,52.707,16.7Z" transform="translate(0 8.242)"></path>
                        <path id="path-5" data-name="path" className="table_white_home_3" d="M1,41.285,79.922,86.642l50.5-30.692L52.707,11.5Z" transform="translate(0 5.58)"></path>
                        <path id="path-6" data-name="path" className="table_white_home_3" d="M1.2,36.236,79.97,81.442,130.619,50.75,52.756,6.3Z" transform="translate(0.102 2.918)"></path>
                        <path id="path-7" data-name="path" className="table_white_home_6" d="M1.2,30.385,79.97,75.591,130.619,44.9,52.756.6Z" transform="translate(0.102)"></path>
                      </g>
                      <g id="group-57-2" data-name="group-57" transform="translate(1304 736.716)">
                        <path id="path-8" data-name="path" className="table_white_home_3" d="M1,46.485,79.922,91.842,130.419,61,52.707,16.7Z" transform="translate(0 8.242)"></path>
                        <path id="path-9" data-name="path" className="table_white_home_3" d="M1,41.285,79.922,86.642l50.5-30.692L52.707,11.5Z" transform="translate(0 5.58)"></path>
                        <path id="path-10" data-name="path" className="table_white_home_6" d="M1.2,36.236,79.97,81.442,130.619,50.75,52.756,6.3Z" transform="translate(0.102 2.918)"></path>
                        <path id="path-11" data-name="path" className="table_white_home_3" d="M1.2,30.385,79.97,75.591,130.619,44.9,52.756.6Z" transform="translate(0.102)"></path>
                      </g>
                      <path id="path-12" data-name="path" className="table_white_home_7" d="M473.8,275.58,340.58,352.5l233.143,134.6,133.225-76.918Z" transform="translate(1049.311 394.941)"></path>
                      <line id="line" className="table_white_home_8" y2="135.693" transform="translate(1623.034 882.035)"></line>
                      <line id="line-2" data-name="line" className="table_white_home_8" y2="138.201" transform="translate(1756.258 805.117)"></line>
                      <line id="line-3" data-name="line" className="table_white_home_8" y2="135.693" transform="translate(1389.891 747.439)"></line>
                      <path id="path-13" data-name="path" className="table_white_home_9" d="M409.53,334.27" transform="translate(1115.937 452.725)"></path>
                      <path id="path-14" data-name="path" className="table_white_home_8" d="M400.27,366.765l101.8,56.6,66.612-38.459V307.99l-99.918-55.21v76.271Z" transform="translate(1106.565 373.072)"></path>
                      <path id="path-1" className="table_white_home_2" d="M1126.948,672.671l.176,2.915,4.39,72.8,73.015-41.985-82.37-47.587Z" transform="translate(377.661 175.306)"></path>
                      <line id="line-4" data-name="line" className="table_white_home_9" x1="65.672" y1="37.479" transform="translate(1516.378 843.66)"></line>
                      <path id="path-15" data-name="path" className="table_white_home_10" d="M391.24,442.815l66.612-37.558V383.53l-65.476,38.459" transform="translate(1124.198 497.608)"></path>
                      <path id="path-16" data-name="path" className="table_white_home_10" d="M340.23,450.85l.02-135.83,102.9,58.4-2.978,135.105Z" transform="translate(1075.27 431.895)"></path>
                      <line id="line-5" data-name="line" className="table_white_home_8" y2="76.918" transform="translate(1515.144 940.678)"></line>
                      <line id="line-6" data-name="line" className="table_white_home_8" x1="0.274" y2="77.192" transform="translate(1415.225 882.726)"></line>
                      <line id="line-7" data-name="line" className="table_white_home_8" y2="76.095" transform="translate(1582.05 902.866)"></line>
                      <line id="line-8" data-name="line" className="table_white_home_9" x2="99.918" y2="55.856" transform="translate(1575.328 702.123)"></line>
                      <path id="path-17" data-name="path" className="table_white_home_7" d="M417.27,316.182l33.306-18.612,79.249,44.376-32.816,18.789Z" transform="translate(1122.871 416.034)"></path>
                      <path id="path-4-2" data-name="path-4" className="table_white_home_11" d="M1628.637,4579.2v-64.132l91.223,50.359v65.338Z" transform="translate(-49.24 -3881.285)"></path>
                      <path id="path-34" className="table_white_home_12" d="M3688.191,5221.893l74.023,40.311-1,25.414-73.023-40.742Z" transform="translate(-2100 -4575)"></path>
                      <path id="path-34-2" data-name="path-34" className="table_white_home_12" d="M3690.191,5218.893l31.027,17.06v14.778l-31.027-17.112Z" transform="translate(-2103 -4538.904)"></path>
                      <path id="path-34-3" data-name="path-34" className="table_white_home_12" d="M3690.191,5217.893l32.027,18.06v15.778l-32.027-18.112Z" transform="translate(-2063 -4515.904)"></path>
                      <path id="path-18" data-name="path" className="table_white_home_3" d="M1.2,23.614l60.864,34.93L101.2,34.829,41.036.6Z" transform="translate(1273.771 939.823)"></path>
                      <path id="path-19" data-name="path" className="table_white_home_13" d="M1.2,15.713,41.169,38.651l25.7-15.573L27.36.6Z" transform="translate(1722.102 959.716)"></path>
                      <g id="group" transform="translate(1729 967.087)">
                        <g id="group-2" data-name="group" transform="translate(15.7 5.8)">
                          <g id="group-3" data-name="group">
                            <path id="path-20" data-name="path" className="table_white_home_12" d="M29.608,7.464,20,5.8,18,6.934l2.911,5.558,2.344-1.361L21.516,7.842l5.709.983,2.382-1.361" transform="translate(-19.13 -7.8)"></path>
                            <path id="path-21" data-name="path" className="table_white_home_12" d="M34.454,17.232,33.017,16.4,15.7,20.9l1.437.832,17.317-4.5" transform="translate(-14.7 -13.392)"></path>
                            <path id="path-22" data-name="path" className="table_white_home_12" d="M52,20.3l-2.344,1.4,1.739,3.289-5.709-1.021-2.382,1.4,9.6,1.664,2-1.134L52,20.3" transform="translate(-30.864 -13.818)"></path>
                          </g>
                        </g>
                      </g>
                      <g id="group-180" transform="translate(1671 536.763)">
                        <g id="group-4" data-name="group">
                          <g id="group-5" data-name="group">
                            <g id="group-6" data-name="group" className="table_white_home_14">
                              <g id="group-7" data-name="group">
                                <g id="group-8" data-name="group">
                                  <g id="group-9" data-name="group">
                                    <path id="path-23" data-name="path" d="M2.984,123.358l.332-118.3,170.2,98.239-.332,118.3L2.984,123.358m173.431-21.72L.332,0,0,125.1,176.084,226.737l.332-125.1"></path>
                                    <path id="path-24" data-name="path" d="M25.858,131.477l.083-17.327,27.606,15.917-.083,17.327L25.858,131.477m29.513-2.4-15.005-8.622-.332.912-.58-.5.249-.746L24.2,111v8.539c.332.829.746,1.741,1.161,2.57l-.5.083c-.249-.5-.415-.995-.663-1.492v11.606L55.371,150.3V139.933l-.912-.5v-.663l.912.5v-10.2" transform="translate(-4.138 -18.979)"></path>
                                    <path id="path-25" data-name="path" d="M105.332,157.868l-.415-.249v.332l.415-.083M90.658,168.894l.083-17.327,27.606,15.917-.083,17.327L90.658,168.894m29.513-2.4-13.845-7.959-2.073.5v-1.658L89,148.5v10.363l.912.5v.663l-.912-.5v10.363l31.171,17.99V177.516l-.912-.5v-.663l.912.5V166.49" transform="translate(-15.217 -25.39)"></path>
                                    <path id="path-26" data-name="path" d="M143.824,133.094l.083-17.327,27.606,15.917-.083,17.327-27.606-15.917m29.43-2.4-16.415-9.451c.332.415.663.912.995,1.326l-.332.415c-.58-.829-1.244-1.575-1.824-2.4l-13.6-7.876L142,134.089l13.845,7.959,1.575-.332.332.746-.912.249,16.332,9.451v-9.782a14.431,14.431,0,0,0-.995-1.161l.249-.414a7.477,7.477,0,0,0,.746.829l.083-10.943" transform="translate(-24.279 -19.269)"></path>
                                    <path id="path-27" data-name="path" d="M37.941,71.994l.083-17.327L65.63,70.585l-.083,17.327L37.941,71.994m29.43-2.4L54.024,61.88c-1.326,0-2.073.083-2.073.083l-.332-.746a6.552,6.552,0,0,1,1.161-.083L36.2,51.6V62.294c.5.083.746.166.829.166l.166.746a9.379,9.379,0,0,0-.995-.166V73.072l15.005,8.622.332-.912.58.5-.332.746,15.586,8.953v-.5c-.58-.415-.912-.663-.912-.663l.083-.58s.332.249.829.663V69.59" transform="translate(-6.189 -8.823)"></path>
                                    <path id="path-28" data-name="path" d="M90.841,102.594l.083-17.327,27.606,15.917-.083,17.327L90.841,102.594m29.513-2.4L89.183,82.2,89.1,103.589l15.254,8.788v-.995l.58.332v.995l15.254,8.788.166-21.306" transform="translate(-15.234 -14.055)"></path>
                                    <path id="path-29" data-name="path" d="M156.158,206.694l.083-17.327,27.606,15.917-.083,17.327-27.606-15.917m29.513-2.4-15.586-9.036c.083.332.249.746.332,1.078l-.58-.083a8.149,8.149,0,0,0-.415-1.326L154.5,186.3v10.363l.912.5v.663l-.912-.5v10.363l31.171,17.99V214.155l-.663.663-.5-.663a8.149,8.149,0,0,0,1.078-1.161l.083-8.7" transform="translate(-26.416 -31.854)"></path>
                                    <path id="path-30" data-name="path" d="M128.393,143.905l-.663.166C140.994,157,152.352,170.1,155.419,179.636c.166.5.249.912.415,1.326l.58.083a5.362,5.362,0,0,0-.332-1.078c-2.653-8.622-11.938-20.726-27.689-36.062m-26.28-23.544v.746c8.124,6.8,16.829,14.508,24.871,22.3l.663-.166c-4.643-4.56-9.948-9.368-15.751-14.425-3.233-2.984-6.549-5.8-9.782-8.456M72.683,96.9l-.083.58a8.11,8.11,0,0,1,.912.663c3.233,2.487,14.674,11.109,28.021,22.384v-.746C88.1,108.589,76.662,99.8,73.512,97.48a8.509,8.509,0,0,0-.829-.58" transform="translate(-12.413 -16.568)"></path>
                                    <path id="path-31" data-name="path" d="M151.587,142.519l-1.658.415L133.6,146.747l-.58.083-24.539,5.72-.5.166V152.3l.083-27.772v-.663l.083-5.14v-.995l-.663-.332v6.632L107.4,151.97v1.658l2.073-.5,24.207-5.554.663-.166,16.663-3.9.912-.249-.332-.746" transform="translate(-18.363 -20.073)"></path>
                                    <path id="path-32" data-name="path" d="M33.1,64.608s-.332-.083-.829-.166c-2.984-.5-13.513-1.409-17.244,8.207-3.15,8.207-.663,21.637,7.3,40.125.249.5.415.995.663,1.492l.5-.083c-.415-.912-.746-1.741-1.161-2.57-7.461-17.658-9.7-30.508-6.715-38.384,3.565-9.2,13.513-8.539,16.663-8.041.58.083.912.166.995.166l-.166-.746" transform="translate(-2.343 -10.97)"></path>
                                    <path id="path-33" data-name="path" d="M52.548,86.7l-.332.912-9.368,28.27-.249.746.58.58.332-.995L52.88,88.026l.249-.746-.58-.58" transform="translate(-7.284 -14.824)"></path>
                                    <path id="path-34-4" data-name="path" d="M100.976,75.946A98.108,98.108,0,0,0,82.9,67.656c-13.6-4.643-23.71-4.643-26.943-4.56a7.827,7.827,0,0,0-1.161.083l.332.746s.746-.083,2.073-.083c3.979-.083,13.43.332,25.783,4.56a103.486,103.486,0,0,1,17.99,8.29c12.1,6.964,26.114,18.073,39.876,35.482.58.746,1.244,1.575,1.824,2.4l.332-.415c-.332-.415-.663-.912-.995-1.326-14.259-18.238-28.6-29.679-41.037-36.891" transform="translate(-9.37 -10.785)"></path>
                                    <path id="path-35" data-name="path" d="M84.99,158.693l-.912-.5L61.529,145.18l-.829-.58v.746l.829.5,22.549,13.016.912.5v-.663" transform="translate(-10.378 -24.724)"></path>
                                    <path id="path-36" data-name="path" d="M150.371,196.442l-.912-.5L126.329,182.6l-.829-.5v.663l.829.5,23.13,13.347.912.5v-.663" transform="translate(-21.458 -31.135)"></path>
                                    <path id="path-37" data-name="path" d="M51.089,133.952,29.7,121.6v.663l21.389,12.352v-.663" transform="translate(-5.078 -20.791)"></path>
                                    <path id="path-38" data-name="path" d="M116.589,171.752,95.2,159.4v.663l21.389,12.352v-.663" transform="translate(-16.277 -27.254)"></path>
                                    <path id="path-39" data-name="path" d="M181.706,209.352,160.4,197v.663l21.306,12.352v-.663" transform="translate(-27.425 -33.683)"></path>
                                    <path id="path-40" data-name="path" d="M169.706,136.27,148.4,124v.663l21.306,12.352v-.746" transform="translate(-25.373 -21.201)"></path>
                                    <path id="path-41" data-name="path" d="M116.389,105.552,95,93.2v.663l21.389,12.352v-.663" transform="translate(-16.243 -15.935)"></path>
                                    <path id="path-42" data-name="path" d="M63.489,74.952,42.1,62.6v.663L63.489,75.616v-.663" transform="translate(-7.198 -10.703)"></path>
                                    <path id="path-43" data-name="path" d="M42.28,72.691V68.878L63.089,80.9v3.813L42.28,72.691m21.389,7.793L41.7,67.8v5.223L63.669,85.707V80.484" transform="translate(-7.13 -11.592)"></path>
                                    <path id="path-44" data-name="path" d="M95.58,103.491V99.678L116.389,111.7v3.813L95.58,103.491m21.389,7.793L95,98.6v5.223l21.969,12.684v-5.223" transform="translate(-16.243 -16.859)"></path>
                                    <path id="path-45" data-name="path" d="M148.58,134.008v-3.813l20.808,12.021v3.814L148.58,134.008m21.389,7.876L148,129.2v5.223l21.969,12.684v-5.223" transform="translate(-25.305 -22.091)"></path>
                                    <path id="path-46" data-name="path" d="M161.28,207.208v-3.814l20.808,12.021v3.813L161.28,207.208m21.389,7.876L160.7,202.4v5.223l21.969,12.684v-5.223" transform="translate(-27.476 -34.606)"></path>
                                    <path id="path-47" data-name="path" d="M95.38,169.208v-3.814l20.808,12.021v3.814L95.38,169.208m21.389,7.876L94.8,164.4v5.223l21.969,12.684v-5.223" transform="translate(-16.209 -28.109)"></path>
                                    <path id="path-48" data-name="path" d="M30.28,131.608v-3.813l20.808,12.021v3.813L30.28,131.608m21.389,7.876L29.7,126.8v5.223l21.969,12.684v-5.223" transform="translate(-5.078 -21.68)"></path>
                                    <path id="path-49" data-name="path" d="M178.749,146.5l-.249.415a14.436,14.436,0,0,0,.995,1.161c9.534,11.275,15.171,22.052,16.829,32.083,2.4,14.176-3.979,23.047-6.632,26.031-.663.746-1.078,1.078-1.078,1.161l.5.663.663-.663c2.321-2.4,9.782-11.606,7.212-27.026-1.741-10.363-7.627-21.472-17.409-33a7.812,7.812,0,0,1-.829-.829" transform="translate(-30.52 -25.049)"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g id="group-182" transform="translate(1328 749)">
                        <g id="group-10" data-name="group">
                          <g id="group-11" data-name="group">
                            <g id="group-12" data-name="group" className="table_white_home_15">
                              <g id="group-13-2" data-name="group" transform="translate(5.437 2.498)">
                                <g id="group-14" data-name="group">
                                  <g id="group-15" data-name="group">
                                    <path id="path-50" data-name="path" d="M24.481,3.463,15.517,1.7,13.9,2.729l3.086,5.143L18.9,6.7l-2.057-3.38L22.57,4.639l1.91-1.176" transform="translate(1.089 -1.7)"></path>
                                    <path id="path-51" data-name="path" d="M33.616,5.929,31.853,4.9,15.1,8.574,16.863,9.6,33.616,5.929" transform="translate(1.653 -0.197)"></path>
                                    <path id="path-52" data-name="path" d="M26.55,12.866a1.063,1.063,0,0,1-.588-.882c0-.441.294-.735.882-1.029a8.285,8.285,0,0,1,1.763-.588,4.536,4.536,0,0,1,1.617.294,1.105,1.105,0,0,1,.735,1.029c0,.441-.294.735-.882,1.029a3.913,3.913,0,0,1-1.763.441,2.279,2.279,0,0,1-1.763-.294m6.172-5.29L30.958,6.4,21.7,11.69l1.91,1.029.882-.588a1.525,1.525,0,0,0,1.029,1.763,5.081,5.081,0,0,0,3.086.588,6.088,6.088,0,0,0,3.38-.882c1.029-.588,1.47-1.176,1.47-1.91a2.073,2.073,0,0,0-1.176-1.763,4.6,4.6,0,0,0-2.792-.588l3.233-1.763" transform="translate(4.752 0.507)"></path>
                                    <path id="path-53" data-name="path" d="M31.533,15.268c-.441-.294-.735-.588-.735-1.029s.294-.735.882-1.029a3.747,3.747,0,0,1,3.527,0c.441.294.735.588.735,1.029s-.294.735-.882,1.029a3.747,3.747,0,0,1-3.527,0m5.29-3.086a7.495,7.495,0,0,0-7.054,0c-1.029.588-1.47,1.176-1.47,2.057,0,.735.588,1.47,1.617,2.057a7.495,7.495,0,0,0,7.054,0c1.029-.588,1.47-1.176,1.47-2.057,0-.735-.588-1.47-1.617-2.057" transform="translate(7.851 2.808)"></path>
                                    <path id="path-54" data-name="path" d="M37.033,18.515a1.063,1.063,0,0,1-.588-.882c0-.441.294-.735.882-1.029a8.285,8.285,0,0,1,1.763-.588,4.536,4.536,0,0,1,1.617.294,1.109,1.109,0,0,1,.588,1.029c0,.441-.294.735-.882,1.029a3.913,3.913,0,0,1-1.763.441,2.907,2.907,0,0,1-1.617-.294m11.169-2.5-1.91-1.029-3.233,1.763a1.784,1.784,0,0,0-1.176-1.617,6.885,6.885,0,0,0-6.466.147A2.754,2.754,0,0,0,33.8,17.339,1.828,1.828,0,0,0,34.976,19.1a5.828,5.828,0,0,0,2.939.588l-.882.588,1.91,1.029,9.258-5.29" transform="translate(10.434 4.263)"></path>
                                    <path id="path-55" data-name="path" d="M52.524,21.062l-2.057-1.176-5.731,1.47,2.5-3.38L45.176,16.8a54.408,54.408,0,0,1-4.262,5.437,1.91,1.91,0,0,1-1.47,0A.889.889,0,0,1,39,21.356l-2.2.294a1.769,1.769,0,0,0,1.029,1.47,5.265,5.265,0,0,0,1.617.588,4.231,4.231,0,0,0,1.91-.147l11.169-2.5" transform="translate(11.842 5.39)"></path>
                                    <path id="path-56" data-name="path" d="M51.895,19.3l-1.91,1.029,2.2,3.527L46.31,22.533,44.4,23.562l8.964,1.91,1.763-1.029L51.895,19.3" transform="translate(15.411 6.564)"></path>
                                    <path id="path-57" data-name="path" d="M14.281,9.363,5.463,7.6,3.7,8.482l3.233,5.29L8.843,12.6l-2.2-3.38,5.878,1.323,1.763-1.176" transform="translate(-3.7 1.07)"></path>
                                    <path id="path-58" data-name="path" d="M23.369,11.829,21.606,10.8,5,14.474,6.763,15.5l16.606-3.674" transform="translate(-3.09 2.573)"></path>
                                    <path id="path-59" data-name="path" d="M22.669,13.429,20.758,12.4,11.5,17.69l1.91,1.029,3.674-2.057a3.35,3.35,0,0,1,1.617-.441,2.934,2.934,0,0,1,1.323.294c.735.441.588,1.029-.441,1.617l-3.674,2.057,1.91,1.029,4.115-2.351a2.29,2.29,0,0,0,1.323-1.617c0-.588-.294-1.029-1.029-1.47a5.255,5.255,0,0,0-2.792-.588l3.233-1.763" transform="translate(-0.037 3.324)"></path>
                                    <path id="path-60" data-name="path" d="M27.405,16.929,25.495,15.9l-2.057,1.176-.735-.441-1.323.735.735.441-3.086,1.763c-.735.441-1.029.882-1.029,1.47,0,.441.294.882,1.029,1.323a4.419,4.419,0,0,0,2.645.588l.588-1.176c-.588,0-1.029,0-1.323-.294-.147-.147-.294-.294-.294-.441s.147-.294.441-.441l2.939-1.763,1.617.882,1.323-.735L25.348,18.1l2.057-1.176" transform="translate(3.015 4.968)"></path>
                                    <path id="path-61" data-name="path" d="M35.449,23.156a5.264,5.264,0,0,0-1.617-.588,4.181,4.181,0,0,0-1.91,0,1.207,1.207,0,0,0,.147-1.029,1.959,1.959,0,0,0-.735-.882,5.033,5.033,0,0,0-2.645-.588l.735-.441L27.513,18.6,20.9,22.421,22.517,23.6l3.674-2.057a3.35,3.35,0,0,1,1.617-.441,2.934,2.934,0,0,1,1.323.294.808.808,0,0,1,.441.735c-.147.294-.441.441-.882.882l-3.821,2.057,1.91,1.029,3.674-2.057a3.35,3.35,0,0,1,1.617-.441,2.934,2.934,0,0,1,1.323.294c.735.441.588,1.029-.441,1.617l-3.674,2.057,1.91,1.029L35.3,26.242c.882-.588,1.323-1.029,1.323-1.617a2.1,2.1,0,0,0-1.176-1.47" transform="translate(4.376 6.236)"></path>
                                    <path id="path-62" data-name="path" d="M40.122,23.529,38.358,22.5,29.1,27.79l1.91,1.029,9.111-5.29" transform="translate(8.227 8.067)"></path>
                                    <path id="path-63" data-name="path" d="M39.895,24.2l-1.91,1.029,2.2,3.38L34.31,27.433,32.4,28.462l8.964,1.763,1.763-.882L39.895,24.2" transform="translate(9.776 8.865)"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g id="group-185" transform="translate(1287 947)">
                        <g id="group-16" data-name="group">
                          <g id="group-17" data-name="group">
                            <g id="group-18" data-name="group" className="table_white_home_16">
                              <g id="group-19" data-name="group">
                                <g id="group-20" data-name="group">
                                  <g id="group-21" data-name="group">
                                    <path id="path-64" data-name="path" d="M52.45,16.212l1.907.06-.06.715-1.788-.06L29.265,30.457l.06,1.013H28.132l-.06-1.073L3.159,15.973l-1.907-.06.06-.715,1.788.06L26.344,1.788,26.285.775h1.192l.06,1.132,24.914,14.3m3.159.358L26.881,0,0,15.616,28.728,32.185,55.609,16.569"></path>
                                    <path id="path-65" data-name="path" d="M46.246,16.935H45.054l.834,13.947.06,1.132H47.14L47.08,31l-.834-14.066M45.292,1.2H44.1l.06,1.073.834,13.947h1.192L45.352,2.332,45.292,1.2" transform="translate(-17.815 -0.485)"></path>
                                    <path id="path-66" data-name="path" d="M55.206,26.613l-1.907-.06-24.079-.417-1.192-.06L3.888,25.66,2.1,25.6v.715H4.007l24.079.477h1.192l24.139.477h1.788v-.656" transform="translate(-0.848 -10.342)"></path>
                                    <path id="path-67" data-name="path" d="M114.115,40.332,96.652,30.2l-1.252.775,17.463,10.073,1.252-.715" transform="translate(-38.539 -12.2)"></path>
                                    <path id="path-68" data-name="path" d="M110.115,42.673,92.652,32.6l-1.252.715,17.463,10.073,1.252-.715" transform="translate(-36.924 -13.17)"></path>
                                    <path id="path-69" data-name="path" d="M106.115,44.973,88.652,34.9l-1.252.715,17.463,10.073,1.252-.715" transform="translate(-35.308 -14.099)"></path>
                                    <path id="path-70" data-name="path" d="M102.115,47.273,84.652,37.2l-1.252.715,17.463,10.132,1.252-.775" transform="translate(-33.692 -15.028)"></path>
                                    <path id="path-71" data-name="path" d="M94.186,50.856,77.378,41.2,74.1,43.107l16.808,9.656,3.278-1.907" transform="translate(-29.935 -16.644)"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="16551.967 5503.897 902.094 909.399">
  <defs>
   
    <clipPath id="clip-path">
      <path id="path" d="M0,155.068.411,0,218.677,125.986l-.411,155.068Z"></path>
    </clipPath>
    <clipPath id="clip-path-2">
      <path id="path-2" data-name="path" d="M0,18.945,32.607,0,99.277,38.618,66.671,57.563Z"></path>
    </clipPath>
    <clipPath id="clip-path-3">
      <path id="path-3" data-name="path" d="M0,19.357,33.32,0,93.68,34.872,60.36,54.228Z"></path>
    </clipPath>
  </defs>
  <g id="group-229" transform="translate(15279 5154)">
    <path id="line-1" className="clsd-1" d="M863.632,471.963l-489.664-284.4" transform="translate(1310.673 786.037)"></path>
    <g id="group-13" transform="translate(1571.021 349.898)">
      <ellipse id="ellipse" className="clsd-2" cx="67.403" cy="90.884" rx="67.403" ry="90.884" transform="translate(0 11.515) rotate(-4.9)"></ellipse>
      <ellipse id="ellipse-2" data-name="ellipse" className="clsd-3" cx="52.357" cy="80.896" rx="52.357" ry="80.896" transform="translate(6.22 21.781) rotate(-4.9)"></ellipse>
      <path id="path-3-2" data-name="path-3" className="clsd-4" d="M0,65.626,28.411,82.032ZM0,0V0Z" transform="translate(62.13 38.238)"></path>
    </g>
    <g id="group-57" transform="translate(1298.559 864.833)">
      <path id="path-4" data-name="path" className="clsd-5" d="M1,53.62l97.828,56.223,62.595-38.232L65.094,16.7Z" transform="translate(-1 13.473)"></path>
      <path id="path-5" data-name="path" className="clsd-3" d="M1,48.42l97.828,56.223L161.423,66.6,65.094,11.5Z" transform="translate(-1 8.928)"></path>
      <path id="path-6" data-name="path" className="clsd-3" d="M1.2,43.407,98.84,99.443,161.623,61.4,65.107,6.3Z" transform="translate(-0.825 4.382)"></path>
      <path id="path-7" data-name="path" className="clsd-6" d="M1.2,37.52,98.84,93.555l62.782-38.044L65.107.6Z" transform="translate(-0.825 -0.6)"></path>
    </g>
    <g id="group-57-2" data-name="group-57" transform="translate(1312.194 830.125)">
      <path id="path-8" data-name="path" className="clsd-3" d="M1,53.62l97.828,56.223,62.595-38.232L65.094,16.7Z" transform="translate(-1 13.473)"></path>
      <path id="path-9" data-name="path" className="clsd-3" d="M1,48.42l97.828,56.223L161.423,66.6,65.094,11.5Z" transform="translate(-1 8.928)"></path>
      <path id="path-10" data-name="path" className="clsd-6" d="M1.2,43.407,98.84,99.443,161.623,61.4,65.107,6.3Z" transform="translate(-0.825 4.382)"></path>
      <path id="path-11" data-name="path" className="clsd-3" d="M1.2,37.52,98.84,93.555l62.782-38.044L65.107.6Z" transform="translate(-0.825 -0.6)"></path>
    </g>
    <path id="path-12" data-name="path" className="clsd-7" d="M505.719,275.58,340.58,370.924,629.574,537.763l165.139-95.344Z" transform="translate(1076.841 471.749)"></path>
    <line id="line" className="clsd-8" y2="168.199" transform="translate(1706.415 1009.512)"></line>
    <line id="line-2" data-name="line" className="clsd-8" y2="171.308" transform="translate(1871.554 914.168)"></line>
    <line id="line-3" data-name="line" className="clsd-8" y2="168.199" transform="translate(1417.421 842.673)"></line>
    <path id="path-13" data-name="path" className="clsd-9" d="M409.53,334.27" transform="translate(1175.944 557.434)"></path>
    <path id="path-14" data-name="path" className="clsd-8" d="M400.27,394.071l126.186,70.16,82.57-47.672V321.216L485.171,252.78v94.542Z" transform="translate(1162.109 439.179)"></path>
    <path id="path-1" className="clsd-2" d="M1128.1,675.989l.218,3.614,5.441,90.243L1224.26,717.8l-102.1-58.986Z" transform="translate(431.525 291.305)"></path>
    <line id="line-4" data-name="line" className="clsd-9" x1="81.404" y1="46.458" transform="translate(1574.209 961.944)"></line>
    <path id="path-15" data-name="path" className="clsd-10" d="M391.24,457.017l82.57-46.555V383.53L392.649,431.2" transform="translate(1181.803 624.871)"></path>
    <path id="path-16" data-name="path" className="clsd-10" d="M340.23,483.389l.024-168.369L467.8,387.414l-3.691,167.471Z" transform="translate(1108.934 527.003)"></path>
    <line id="line-5" data-name="line" className="clsd-8" y2="95.344" transform="translate(1572.679 1082.204)"></line>
    <line id="line-6" data-name="line" className="clsd-8" x1="0.34" y2="95.684" transform="translate(1448.824 1010.368)"></line>
    <line id="line-7" data-name="line" className="clsd-8" y2="94.324" transform="translate(1655.613 1035.334)"></line>
    <line id="line-8" data-name="line" className="clsd-9" x2="123.854" y2="69.237" transform="translate(1647.281 786.501)"></line>
    <path id="path-17" data-name="path" className="clsd-7" d="M417.27,320.641l41.285-23.071,98.234,55.006-40.678,23.289Z" transform="translate(1186.394 503.162)"></path>
    <path id="path-4-2" data-name="path-4" className="clsd-11" d="M1628.637,4594.563v-79.5l113.076,62.423v80.99Z" transform="translate(23.687 -3813.278)"></path>
    <path id="path-34" className="clsd-12" d="M3688.191,5221.893l91.756,49.967-1.24,31.5-90.517-50.5Z" transform="translate(-2024.966 -4503.853)"></path>
    <path id="path-34-2" data-name="path-34" className="clsd-12" d="M3690.191,5218.893l38.46,21.146v18.318l-38.46-21.211Z" transform="translate(-2028.205 -4459.829)"></path>
    <path id="path-34-3" data-name="path-34" className="clsd-12" d="M3690.191,5217.893l39.7,22.386v19.558l-39.7-22.451Z" transform="translate(-1978.623 -4431.559)"></path>
    <path id="path-18" data-name="path" className="clsd-3" d="M1.2,29.127l75.445,43.3,48.511-29.4L50.579.6Z" transform="translate(1273.771 1081.288)"></path>
    <path id="path-19" data-name="path" className="clsd-13" d="M1.2,19.333,50.744,47.766,82.6,28.462,33.627.6Z" transform="translate(1829.503 1105.946)"></path>
    <g id="group" transform="translate(1855.826 1119.65)">
      <g id="group-2" data-name="group" transform="translate(0 0)">
        <g id="group-3" data-name="group" transform="translate(0 0)">
          <path id="path-20" data-name="path" className="clsd-12" d="M32.388,7.862,20.484,5.8,18,7.206l3.609,6.89,2.906-1.687L22.359,8.331l7.077,1.219,2.953-1.687" transform="translate(-18 -5.8)"></path>
          <path id="path-21" data-name="path" className="clsd-12" d="M38.946,17.431,37.165,16.4,15.7,21.977l1.781,1.031,21.465-5.577" transform="translate(-13.059 -10.192)"></path>
          <path id="path-22" data-name="path" className="clsd-12" d="M54.08,20.3l-2.906,1.734,2.156,4.077-7.077-1.265L43.3,26.58l11.9,2.062,2.484-1.406L54.08,20.3" transform="translate(-26.484 -9.786)"></path>
        </g>
      </g>
    </g>
    <g id="group-180" transform="translate(1765.871 581.528)">
      <g id="group-4" data-name="group">
        <g id="group-5" data-name="group">
          <g id="group-6" data-name="group" className="clsd-14">
            <g id="group-7" data-name="group">
              <g id="group-8" data-name="group">
                <g id="group-9" data-name="group">
                  <path id="path-23" data-name="path" d="M3.7,152.91,4.11,6.268,215.08,128.041l-.411,146.641L3.7,152.91m214.978-26.924L.411,0,0,155.068,218.266,281.053l.411-155.068"></path>
                  <path id="path-24" data-name="path" d="M26.255,136.382l.1-21.477,34.22,19.73-.1,21.477-34.22-19.73m36.583-2.98-18.6-10.687-.411,1.13-.719-.617.308-.925L24.2,111v10.584c.411,1.028.925,2.158,1.439,3.186l-.617.1c-.308-.617-.514-1.233-.822-1.85V137.41l38.638,22.3V146.864l-1.13-.617v-.822l1.13.617V133.4" transform="translate(0.668 3.066)"></path>
                  <path id="path-25" data-name="path" d="M109.244,160.112l-.514-.308v.411l.514-.1M91.055,173.779l.1-21.477,34.22,19.73-.1,21.477-34.22-19.73m36.583-2.98-17.161-9.865-2.569.617V159.5L89,148.5v12.845l1.13.617v.822L89,162.167v12.845l38.638,22.3V184.467l-1.13-.617v-.822l1.13.617V170.8" transform="translate(2.458 4.101)"></path>
                  <path id="path-26" data-name="path" d="M144.261,137.979l.1-21.477,34.22,19.73-.1,21.477-34.22-19.73m36.48-2.98-20.347-11.715c.411.514.822,1.13,1.233,1.644l-.411.514c-.719-1.028-1.541-1.952-2.261-2.98L142.1,112.7l-.1,26.513,17.161,9.865,1.952-.411.411.925-1.13.308,20.244,11.715V149.489a17.888,17.888,0,0,0-1.233-1.439l.308-.514a9.27,9.27,0,0,0,.925,1.028l.1-13.565" transform="translate(3.922 3.113)"></path>
                  <path id="path-27" data-name="path" d="M38.358,76.879l.1-21.477,34.22,19.73-.1,21.477-34.22-19.73m36.48-2.98L58.294,64.342c-1.644,0-2.569.1-2.569.1l-.411-.925a8.122,8.122,0,0,1,1.439-.1L36.2,51.6V64.856c.617.1.925.206,1.028.206l.206.925a11.627,11.627,0,0,0-1.233-.206V78.215L54.8,88.9l.411-1.13.719.617-.411.925,19.319,11.1V99.8c-.719-.514-1.13-.822-1.13-.822l.1-.719s.411.308,1.028.822V73.9" transform="translate(1 1.425)"></path>
                  <path id="path-28" data-name="path" d="M91.258,107.479,91.361,86l34.22,19.73-.1,21.477-34.22-19.73m36.583-2.98L89.2,82.2l-.1,26.513,18.908,10.893v-1.233l.719.411v1.233l18.908,10.893.206-26.41" transform="translate(2.461 2.27)"></path>
                  <path id="path-29" data-name="path" d="M156.555,211.579l.1-21.477,34.22,19.73-.1,21.477-34.22-19.73m36.583-2.98-19.319-11.2c.1.411.308.925.411,1.336l-.719-.1a10.1,10.1,0,0,0-.514-1.644L154.5,186.3v12.845l1.13.617v.822l-1.13-.617v12.845l38.638,22.3V220.828l-.822.822-.617-.822a10.1,10.1,0,0,0,1.336-1.439l.1-10.79" transform="translate(4.267 5.145)"></path>
                  <path id="path-30" data-name="path" d="M141.759,155.166l-.822.206c16.442,16.031,30.52,32.267,34.322,44.085.206.617.308,1.13.514,1.644l.719.1a6.645,6.645,0,0,0-.411-1.336c-3.288-10.687-14.8-25.69-34.322-44.7m-32.575-29.184v.925c10.071,8.426,20.861,17.983,30.829,27.643l.822-.206c-5.755-5.652-12.331-11.612-19.525-17.881-4.008-3.7-8.118-7.193-12.126-10.482M72.7,96.9l-.1.719a10.053,10.053,0,0,1,1.13.822c4.008,3.083,18.189,13.77,34.733,27.746v-.925C91.816,111.389,77.635,100.5,73.73,97.619A10.548,10.548,0,0,0,72.7,96.9" transform="translate(2.005 2.676)"></path>
                  <path id="path-31" data-name="path" d="M162.172,148.537l-2.055.514-20.244,4.727-.719.1-30.417,7.091-.617.206v-.514l.1-34.425v-.822l.1-6.371v-1.233l-.822-.411v8.221l-.1,34.631v2.055l2.569-.617,30.006-6.885.822-.206,20.655-4.83,1.13-.308-.411-.925" transform="translate(2.966 3.242)"></path>
                  <path id="path-32" data-name="path" d="M37.746,64.715s-.411-.1-1.028-.206c-3.7-.617-16.75-1.747-21.374,10.173-3.9,10.173-.822,26.821,9.043,49.737.308.617.514,1.233.822,1.85l.617-.1c-.514-1.13-.925-2.158-1.439-3.186C15.139,101.093,12.364,85.164,16.063,75.4,20.482,64,32.814,64.818,36.719,65.434c.719.1,1.13.206,1.233.206l-.206-.925" transform="translate(0.378 1.772)"></path>
                  <path id="path-33" data-name="path" d="M54.931,86.7l-.411,1.13L42.908,122.872l-.308.925.719.719.411-1.233L55.342,88.344l.308-.925-.719-.719" transform="translate(1.177 2.394)"></path>
                  <path id="path-34-4" data-name="path" d="M112.038,79.029a121.61,121.61,0,0,0-22.4-10.276C72.783,63,60.246,63,56.239,63.1a9.7,9.7,0,0,0-1.439.1l.411.925s.925-.1,2.569-.1c4.933-.1,16.647.411,31.959,5.652a128.276,128.276,0,0,1,22.3,10.276c15,8.632,32.37,22.4,49.428,43.982.719.925,1.541,1.952,2.261,2.98l.411-.514c-.411-.514-.822-1.13-1.233-1.644-17.675-22.608-35.453-36.789-50.867-45.729" transform="translate(1.513 1.742)"></path>
                  <path id="path-35" data-name="path" d="M90.809,162.07l-1.13-.617L61.728,145.319,60.7,144.6v.925l1.028.617,27.951,16.134,1.13.617v-.822" transform="translate(1.676 3.994)"></path>
                  <path id="path-36" data-name="path" d="M156.329,199.878l-1.13-.617-28.671-16.545L125.5,182.1v.822l1.028.617L155.2,200.083l1.13.617v-.822" transform="translate(3.466 5.029)"></path>
                  <path id="path-37" data-name="path" d="M56.213,136.911,29.7,121.6v.822l26.513,15.312v-.822" transform="translate(0.82 3.358)"></path>
                  <path id="path-38" data-name="path" d="M121.713,174.712,95.2,159.4v.822l26.513,15.312v-.822" transform="translate(2.629 4.402)"></path>
                  <path id="path-39" data-name="path" d="M186.81,212.311,160.4,197v.822l26.41,15.312v-.822" transform="translate(4.43 5.441)"></path>
                  <path id="path-40" data-name="path" d="M174.81,139.209,148.4,124v.822l26.41,15.311v-.925" transform="translate(4.098 3.425)"></path>
                  <path id="path-41" data-name="path" d="M121.513,108.512,95,93.2v.822l26.513,15.312v-.822" transform="translate(2.624 2.574)"></path>
                  <path id="path-42" data-name="path" d="M68.613,77.912,42.1,62.6v.822L68.613,78.734v-.822" transform="translate(1.163 1.729)"></path>
                  <path id="path-43" data-name="path" d="M42.419,73.863V69.136l25.793,14.9v4.727l-25.793-14.9m26.513,9.66L41.7,67.8v6.474L68.932,90V83.523" transform="translate(1.152 1.872)"></path>
                  <path id="path-44" data-name="path" d="M95.719,104.663V99.936l25.793,14.9v4.727l-25.793-14.9m26.513,9.66L95,98.6v6.474L122.232,120.8v-6.474" transform="translate(2.624 2.723)"></path>
                  <path id="path-45" data-name="path" d="M148.719,135.16v-4.727l25.793,14.9v4.727l-25.793-14.9m26.513,9.762L148,129.2v6.474L175.232,151.4v-6.474" transform="translate(4.087 3.568)"></path>
                  <path id="path-46" data-name="path" d="M161.419,208.36v-4.727l25.793,14.9v4.727l-25.793-14.9m26.513,9.762L160.7,202.4v6.474L187.932,224.6v-6.474" transform="translate(4.438 5.59)"></path>
                  <path id="path-47" data-name="path" d="M95.519,170.36v-4.727l25.793,14.9v4.727l-25.793-14.9m26.513,9.762L94.8,164.4v6.474L122.032,186.6v-6.474" transform="translate(2.618 4.54)"></path>
                  <path id="path-48" data-name="path" d="M30.419,132.76v-4.727l25.793,14.9v4.727l-25.793-14.9m26.513,9.762L29.7,126.8v6.474L56.932,149v-6.474" transform="translate(0.82 3.502)"></path>
                  <path id="path-49" data-name="path" d="M178.808,146.5l-.308.514a17.893,17.893,0,0,0,1.233,1.439c11.818,13.976,18.805,27.335,20.861,39.769,2.98,17.572-4.933,28.568-8.221,32.267-.822.925-1.336,1.336-1.336,1.439l.617.822.822-.822c2.877-2.98,12.126-14.387,8.94-33.5-2.158-12.845-9.454-26.615-21.58-40.9a9.685,9.685,0,0,1-1.028-1.028" transform="translate(4.93 4.046)"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="group-182" transform="translate(1340.703 844.608)">
      <g id="group-10" data-name="group">
        <g id="group-11" data-name="group">
          <g id="group-12" data-name="group" className="clsd-15">
            <g id="group-13-2" data-name="group" transform="translate(6.74 3.097)">
              <g id="group-14" data-name="group">
                <g id="group-15" data-name="group">
                  <path id="path-50" data-name="path" d="M27.016,3.886,15.9,1.7l-2,1.275,3.825,6.376,2.368-1.457L17.543,3.7l7.1,1.639,2.368-1.457" transform="translate(4.68 -1.7)"></path>
                  <path id="path-51" data-name="path" d="M38.052,6.175,35.866,4.9,15.1,9.454l2.186,1.275L38.052,6.175" transform="translate(5.666 0.929)"></path>
                  <path id="path-52" data-name="path" d="M27.711,14.415a1.317,1.317,0,0,1-.729-1.093c0-.546.364-.911,1.093-1.275a10.269,10.269,0,0,1,2.186-.729,5.623,5.623,0,0,1,2,.364,1.37,1.37,0,0,1,.911,1.275c0,.546-.364.911-1.093,1.275a4.85,4.85,0,0,1-2.186.546,2.825,2.825,0,0,1-2.186-.364m7.651-6.558L33.176,6.4,21.7,12.958l2.368,1.275,1.093-.729a1.891,1.891,0,0,0,1.275,2.186,6.3,6.3,0,0,0,3.825.729,7.546,7.546,0,0,0,4.19-1.093c1.275-.729,1.822-1.457,1.822-2.368a2.57,2.57,0,0,0-1.457-2.186,5.7,5.7,0,0,0-3.461-.729l4.008-2.186" transform="translate(11.089 2.162)"></path>
                  <path id="path-53" data-name="path" d="M32.308,16.218c-.546-.364-.911-.729-.911-1.275s.364-.911,1.093-1.275a4.645,4.645,0,0,1,4.372,0c.546.364.911.729.911,1.275s-.364.911-1.093,1.275a4.645,4.645,0,0,1-4.372,0m6.558-3.825a9.29,9.29,0,0,0-8.744,0c-1.275.729-1.822,1.457-1.822,2.55,0,.911.729,1.822,2,2.55a9.29,9.29,0,0,0,8.744,0c1.275-.729,1.822-1.457,1.822-2.55,0-.911-.729-1.822-2-2.55" transform="translate(16.511 6.187)"></path>
                  <path id="path-54" data-name="path" d="M37.808,19.5a1.317,1.317,0,0,1-.729-1.093c0-.546.364-.911,1.093-1.275a10.269,10.269,0,0,1,2.186-.729,5.623,5.623,0,0,1,2,.364,1.375,1.375,0,0,1,.729,1.275c0,.546-.364.911-1.093,1.275a4.85,4.85,0,0,1-2.186.546,3.6,3.6,0,0,1-2-.364m13.844-3.1-2.368-1.275-4.008,2.186a2.212,2.212,0,0,0-1.457-2,8.535,8.535,0,0,0-8.015.182,3.413,3.413,0,0,0-2,2.55,2.266,2.266,0,0,0,1.457,2.186,7.224,7.224,0,0,0,3.643.729l-1.093.729,2.368,1.275L51.652,16.4" transform="translate(21.03 8.734)"></path>
                  <path id="path-55" data-name="path" d="M56.291,22.083l-2.55-1.457-7.1,1.822,3.1-4.19L47.183,16.8c-3.279,4.372-4.918,6.558-5.283,6.74a2.368,2.368,0,0,1-1.822,0,1.1,1.1,0,0,1-.546-1.093l-2.732.364a2.193,2.193,0,0,0,1.275,1.822,6.526,6.526,0,0,0,2,.729,5.244,5.244,0,0,0,2.368-.182l13.844-3.1" transform="translate(23.495 10.706)"></path>
                  <path id="path-56" data-name="path" d="M53.69,19.3l-2.368,1.275,2.732,4.372-7.286-1.639L44.4,24.583l11.112,2.368L57.7,25.676,53.69,19.3" transform="translate(29.739 12.76)"></path>
                  <path id="path-57" data-name="path" d="M16.816,9.786,5.886,7.6,3.7,8.693l4.008,6.558,2.368-1.457L7.343,9.6l7.286,1.639,2.186-1.457" transform="translate(-3.7 3.147)"></path>
                  <path id="path-58" data-name="path" d="M27.77,12.075,25.584,10.8,5,15.354l2.186,1.275L27.77,12.075" transform="translate(-2.632 5.777)"></path>
                  <path id="path-59" data-name="path" d="M25.344,13.675,22.976,12.4,11.5,18.958l2.368,1.275,4.554-2.55a4.152,4.152,0,0,1,2-.546,3.637,3.637,0,0,1,1.639.364c.911.546.729,1.275-.546,2l-4.554,2.55,2.368,1.275,5.1-2.915a2.839,2.839,0,0,0,1.639-2c0-.729-.364-1.275-1.275-1.822a6.513,6.513,0,0,0-3.461-.729l4.008-2.186" transform="translate(2.708 7.091)"></path>
                  <path id="path-60" data-name="path" d="M29.658,17.175,27.29,15.9l-2.55,1.457-.911-.546-1.639.911.911.546-3.825,2.186C18.364,21,18,21.547,18,22.276c0,.546.364,1.093,1.275,1.639a5.477,5.477,0,0,0,3.279.729l.729-1.457c-.729,0-1.275,0-1.639-.364-.182-.182-.364-.364-.364-.546s.182-.364.546-.546l3.643-2.186,2,1.093,1.639-.911-2-1.093,2.55-1.457" transform="translate(8.049 9.967)"></path>
                  <path id="path-61" data-name="path" d="M38.934,24.247a6.525,6.525,0,0,0-2-.729,5.182,5.182,0,0,0-2.368,0,1.5,1.5,0,0,0,.182-1.275,2.428,2.428,0,0,0-.911-1.093,6.238,6.238,0,0,0-3.279-.729l.911-.546L29.1,18.6l-8.2,4.736,2,1.457,4.554-2.55a4.152,4.152,0,0,1,2-.546,3.637,3.637,0,0,1,1.639.364,1,1,0,0,1,.546.911c-.182.364-.546.546-1.093,1.093l-4.736,2.55,2.368,1.275,4.554-2.55a4.152,4.152,0,0,1,2-.546,3.637,3.637,0,0,1,1.639.364c.911.546.729,1.275-.546,2l-4.554,2.55,2.368,1.275,5.1-2.915c1.093-.729,1.639-1.275,1.639-2a2.607,2.607,0,0,0-1.457-1.822" transform="translate(10.432 12.185)"></path>
                  <path id="path-62" data-name="path" d="M42.762,23.775,40.576,22.5,29.1,29.058l2.368,1.275,11.294-6.558" transform="translate(17.169 15.389)"></path>
                  <path id="path-63" data-name="path" d="M41.69,24.2l-2.368,1.275,2.732,4.19-7.286-1.457L32.4,29.483l11.112,2.186L45.7,30.576,41.69,24.2" transform="translate(19.88 16.786)"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="group-185" transform="translate(1289.882 1090.04)">
      <g id="group-16" data-name="group">
        <g id="group-17" data-name="group">
          <g id="group-18" data-name="group" className="clsd-16">
            <g id="group-19" data-name="group">
              <g id="group-20" data-name="group">
                <g id="group-21" data-name="group">
                  <path id="path-64" data-name="path" d="M65.015,20.1l2.364.074-.074.887-2.216-.074L36.275,37.753l.074,1.256H34.872l-.074-1.33L3.916,19.8l-2.364-.074.074-.887,2.216.074,28.813-16.7L32.581.96h1.478l.074,1.4L65.015,20.1m3.916.443L33.32,0,0,19.357,35.61,39.9,68.93,20.539"></path>
                  <path id="path-65" data-name="path" d="M46.76,20.7H45.282l1.034,17.288.074,1.4h1.478l-.074-1.256L46.76,20.7M45.578,1.2H44.1l.074,1.33,1.034,17.288h1.478L45.651,2.6l-.074-1.4" transform="translate(-11.519 -0.313)"></path>
                  <path id="path-66" data-name="path" d="M67.927,26.856l-2.364-.074-29.848-.517-1.478-.074L4.316,25.674,2.1,25.6v.887H4.464l29.848.591h1.478l29.922.591h2.216v-.813" transform="translate(-0.549 -6.687)"></path>
                  <path id="path-67" data-name="path" d="M118.6,42.76,96.951,30.2l-1.551.96,21.647,12.486,1.551-.887" transform="translate(-24.918 -7.888)"></path>
                  <path id="path-68" data-name="path" d="M114.6,45.086,92.951,32.6l-1.551.887,21.647,12.486,1.551-.887" transform="translate(-23.873 -8.515)"></path>
                  <path id="path-69" data-name="path" d="M110.6,47.386,88.951,34.9l-1.551.887,21.647,12.486,1.551-.887" transform="translate(-22.829 -9.116)"></path>
                  <path id="path-70" data-name="path" d="M106.6,49.686,84.951,37.2l-1.551.887,21.647,12.56,1.552-.96" transform="translate(-21.784 -9.717)"></path>
                  <path id="path-71" data-name="path" d="M99,53.169,78.163,41.2,74.1,43.564,94.934,55.533,99,53.169" transform="translate(-19.355 -10.761)"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>}
</React.Fragment>
    )
} 
export default SlideHomeBoardTable;