import React from 'react';

const Greenbg = () =>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" viewBox="0 0 245 100">
    <polygon fill="#66C293" points="0,0 245.4,12.5 236.4,100.6 0,100.6 "></polygon>
  </svg>
  )
}

export default Greenbg;