import React from 'react'
import './style.scss'
const SlideHomeBoardWhilteSvg =()=>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="-1.205 1594.086 763.623 578.72">
                    <defs>
                      <clipPath id="board_white_home_clip-path">
                        <rect id="rectangle-19" className="board_white_home_1" width="70" height="227" transform="translate(142 568)"></rect>
                      </clipPath>
                      <clipPath id="board_white_home_clip-path-2">
                        <path id="path" d="M0,18.959,32.583,0,80.2,27.442,47.557,46.4Z"></path>
                      </clipPath>
                    </defs>
                    <g id="group-230" transform="translate(0 1092)">
                      <g id="group-57" transform="translate(367 826.716)">
                        <path id="path-2" data-name="path" className="board_white_home_2" d="M1,46.485,79.922,91.842,130.419,61,52.707,16.7Z" transform="translate(0 8.242)"></path>
                        <path id="path-3" data-name="path" className="board_white_home_3" d="M1,41.285,79.922,86.642l50.5-30.692L52.707,11.5Z" transform="translate(0 5.58)"></path>
                        <path id="path-4" data-name="path" className="board_white_home_4" d="M1.2,36.236,79.97,81.442,130.619,50.75,52.756,6.3Z" transform="translate(0.102 2.918)"></path>
                        <path id="path-5" data-name="path" className="board_white_home_3" d="M1.2,30.385,79.97,75.591,130.619,44.9,52.756.6Z" transform="translate(0.102)"></path>
                      </g>
                      <g id="group-12" transform="translate(97.288 504.68)">
                        <path id="path-6" data-name="path" className="board_white_home_5" d="M.5,93.247V351.929l160.017-92.377V.87Z" transform="translate(0 0)"></path>
                        <path id="path-7" data-name="path" className="board_white_home_6" d="M101.5,250.894l-32-18.487V10.68" transform="translate(60.896 8.658)"></path>
                        <line id="line" className="board_white_home_6" x1="128.014" y2="73.909" transform="translate(0.5 241.065)"></line>
                      </g>
                      <path id="line-1" className="board_white_home_6" d="M247.047,368.008,615.07,154.867" transform="translate(-247.5 711.5)"></path>
                      <g id="group-mask-3" className="board_white_home_7" transform="translate(-42 9)">
                        <ellipse id="ellipse-8" className="board_white_home_8" cx="33.5" cy="58.5" rx="33.5" ry="58.5" transform="translate(111 618)"></ellipse>
                      </g>
                      <path id="path-8" data-name="path" className="board_white_home_4" d="M1.2,26.857,70.641,66.709l44.65-27.057L46.65.6Z" transform="translate(545.891 813.687)"></path>
                      <path id="path-9" data-name="path" className="board_white_home_2" d="M1.2,24.295,63.865,60.258l40.293-24.416L42.215.6Z" transform="translate(193.102 980.716)"></path>
                      <path id="path-10" data-name="path" className="board_white_home_9" d="M1.2,31.574,83.117,78.586l52.672-31.918L54.816.6Z" transform="translate(625.642 927.749)"></path>
                      <g id="group-106" transform="translate(380 833.4)">
                        <path id="path-11" data-name="path" className="board_white_home_10" d="M21.5,5.158,33.807,12.3l7.9-4.862L29.553.6Z" transform="translate(10.647)"></path>
                        <path id="path-12" data-name="path" className="board_white_home_11" d="M1,20.018,47.948,46.911,70.586,33.237,24.246,6.8Z" transform="translate(0 3.22)"></path>
                        <path id="path-13" data-name="path" className="board_white_home_11" d="M35.7,39.618l12,7.141L70.341,33.085,58.794,26.4Z" transform="translate(18.022 13.399)"></path>
                        <path id="path-14" data-name="path" className="board_white_home_12" d="M83.24,33.483C70.477,26.038,57.563,18.745,44.8,11.3" transform="translate(22.748 5.557)"></path>
                      </g>
                      <g id="group" transform="translate(659 948.087)">
                        <g id="group-2" data-name="group" transform="translate(14.57 3.8)">
                          <g id="group-3" data-name="group" transform="translate(0)">
                            <path id="path-15" data-name="path" className="board_white_home_13" d="M39.555,8.889,21.721,5.8,18,7.906l5.406,10.321L27.76,15.7,24.53,9.591l10.6,1.826,4.423-2.528" transform="translate(-18 -5.8)"></path>
                            <path id="path-16" data-name="path" className="board_white_home_13" d="M50.525,17.945,47.857,16.4,15.7,24.755,18.368,26.3l32.157-8.355" transform="translate(-11.744 -7.101)"></path>
                            <path id="path-17" data-name="path" className="board_white_home_13" d="M59.449,20.3,55.1,22.9l3.23,6.108-10.6-1.9-4.423,2.6L61.134,32.8l3.721-2.106L59.449,20.3" transform="translate(-18.108 -4.548)"></path>
                          </g>
                        </g>
                      </g>
                      <g id="group-4" data-name="group" transform="translate(220 995)">
                        <g id="group-5" data-name="group" transform="translate(2.923)">
                          <g id="group-6" data-name="group">
                            <path id="path-18" data-name="path" className="board_white_home_14" d="M49.307,21.1l-6.483-3.736c-3.407,1.978-4.505,2.088-7.033.659a.384.384,0,0,1-.22-.11l-3.407-1.978c-2.967-1.648-4.615-1.978-7.582-1.648.769-1.648,0-2.747-2.747-4.4L18.1,7.8c-2.857-1.648-2.747-2.088.769-4.176L12.824,0A26.682,26.682,0,0,1,9.088,2.088C5.572,4.176,4.583,5.165,4.8,6.593c.11.989,1.1,2.088,3.3,3.3l5.714,3.3c2.418,1.429,2.747,2.308,1.429,3.516l5.6,3.187c2.088-.769,3.407-.659,5.714.659l5.934,3.407a10.274,10.274,0,0,0,6.044,1.758c2.308-.11,3.736-.659,7.363-2.747A23.318,23.318,0,0,0,49.307,21.1" transform="translate(-4.774)"></path>
                          </g>
                        </g>
                      </g>
                      <g id="group-186" transform="translate(564 822.8)">
                        <g id="group-7" data-name="group">
                          <g id="group-8" data-name="group">
                            <g id="group-9" data-name="group" className="board_white_home_15">
                              <g id="group-10" data-name="group">
                                <g id="group-11" data-name="group">
                                  <g id="group-12-2" data-name="group">
                                    <path id="path-19" data-name="path" d="M88.888,27.442,41.266,0,24.3,9.9l1.35-.835,1.414.835,14.267-8.29L86.124,27.442l-14.267,8.29,1.35.835,15.681-9.126" transform="translate(-8.683)"></path>
                                    <path id="path-20" data-name="path" d="M47.814,31.9,18.38,14.935,16.966,14.1l-1.35.835L0,24,32.2,42.57,30.784,41.8l.257-.193-1.093-1.864L4.884,25.218l-3.792-.707.578-1.028,1.478.257,13.432-7.776-.514-.9,1.864-.321,1.221,2.185L44.215,31.452l3.342.578.257-.129" transform="translate(0 -5.038)"></path>
                                    <path id="path-21" data-name="path" d="M50.628,51.7l14.267-8.29L78.9,51.5l-14.267,8.29-14.01-8.1M81.6,51.5l-1.35-.771L64.895,41.8l-.257.129.514.064-.578,1.093L63.1,42.828l-13.432,7.84.45.835-1.864.321-.193-.321-.257.129,1.35.771,15.488,8.933L81.6,51.5" transform="translate(-17.081 -14.937)"></path>
                                    <path id="path-22" data-name="path" d="M68.472,23.1,48.55,11.6l-1.35.835,19.923,11.5,1.35-.835" transform="translate(-16.866 -4.145)"></path>
                                    <path id="path-23" data-name="path" d="M55.046,20.334,44.25,14.1l-1.35.835,10.8,6.234,1.35-.835" transform="translate(-15.33 -5.038)"></path>
                                    <path id="path-24" data-name="path" d="M35.026,29.56l-2.314-.45,6.17,10.925,1.093,1.928.193.321,1.864-.321-.45-.835L35.026,29.56M26.864,15.1,25,15.421l.514.835,6.491,11.5,2.249.45-6.17-10.925L26.864,15.1" transform="translate(-8.933 -5.396)"></path>
                                    <path id="path-25" data-name="path" d="M48.679,37.376l-.514-.129-3.342-.578L25.928,33.134l-2.249-.45L3.757,28.957,2.278,28.7,1.7,29.728l3.792.707L24.386,33.97l2.314.45,19.923,3.727L48.1,38.4l.578-1.028" transform="translate(-0.607 -10.256)"></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
    )
}
export default SlideHomeBoardWhilteSvg;